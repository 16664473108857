import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { API, token } from "../../constants";

export default function RequestLeave({
  closeLeaveModal,
  month,
  linkDate,
  annualId,
}) {
  const routeId = sessionStorage.getItem("routeId");
  const [requestDate, setRequestDate] = useState();
  const [leaveDate, setLeaveDate] = useState();
  console.log(annualId);
  const getDate = async () => {
    const res = await axios.get(
      `${API}/work/${routeId}/setting?yearMonth=${month}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorizaiton: `Bearer ${token}`,
        },
      }
    );
    console.log(res);
    setRequestDate(res.data.object.requestTerm);
  };
  const getLeave = async () => {
    const res = await axios.get(`${API}/driver/leave?yearMonth=${month}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res);
    setLeaveDate(res.data.object);
  };
  const period =
    requestDate?.slice(5, 7) +
    "월 " +
    requestDate?.slice(8, 10) +
    "일 - " +
    requestDate?.slice(16, 18) +
    "월 " +
    requestDate?.slice(19, 21) +
    "일";
  const today = linkDate.slice(5, 7) + "월 " + linkDate.slice(8, 10) + "일";
  useEffect(() => {
    getDate();
    getLeave();
  }, []);

  const [selectDate, setSelectDate] = useState([]);
  const onCheckedDate = (checked, data) => {
    if (checked) {
      setSelectDate([...selectDate, data]);
    } else if (!checked) {
      setSelectDate(selectDate.filter((el) => el !== data));
    }
  };
  // const onRemove = (data) => {
  //   setSelectDate(selectDate.filter((el) => el !== data));
  // };

  console.log(selectDate);
  let selectId = [];
  for (let i = 0; i < leaveDate?.length; i++) {
    for (let j = 0; j < selectDate?.length; j++)
      if (selectDate[j] === leaveDate[i].date) {
        selectId.push(leaveDate[i].id);
      }
  }
  console.log(selectId);
  console.log(annualId);
  const onLeave = async (data) => {
    data.preventDefault();
    const userData = {
      originLeaves: selectId ,
      workId: annualId ,
    };
    try {
      const res = await axios.post(`${API}/driver/leave/request`, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("휴무신청 성공");
      console.log(res);
    } catch (err) {
      console.log(err.response.data.message);
      console.log(err);
    }
  };

  return (
    <>
      <AlertRequest class="common-popup type-popup alert-request">
        <TitleBox class="title-box">
          <Title class="title">
            휴무 신청 기간
            <br />
            <span>2022년 2월</span>
          </Title>
          <div className="date">{period}</div>
        </TitleBox>

        <ContentBox class="content-box">
          <BtnNotify class="btn-popup btn-notify">
            <img src="/image/ico_notify.png" alt="도움말" />
          </BtnNotify>

          <ContTitle class="title">
            휴무 신청일
            <br />
            <span>{today}</span>
          </ContTitle>
          <SubTitle class="sub-title">
            <strong>변경할 기존 휴무일</strong>
            <br />
            (중복 선택 가능)
          </SubTitle>

          <SwiperRequest>
            <Swiper>
              <SwiperWrapper>
                {leaveDate &&
                  leaveDate.map((data) => {
                    return (
                      <SwiperSlide>
                        <label>
                          <input
                            type={"checkbox"}
                            value={data.date}
                            onChange={(e) => {
                              onCheckedDate(e.target.checked, e.target.value);
                            }}
                            checked={
                              selectDate.includes(data.date) ? true : false
                            }
                          />
                          <span>{data.date.slice(-2)}</span>
                        </label>
                      </SwiperSlide>
                    );
                  })}
              </SwiperWrapper>
            </Swiper>
          </SwiperRequest>

          <NotifyTxt class="notify-txt">휴무일을 선택해주세요.</NotifyTxt>

          <BtnBox class="btn-box">
            <BtnCancel onClick={closeLeaveModal}>취소</BtnCancel>
            <BtnConfirm disabled={false} onClick={onLeave}>
              확인
            </BtnConfirm>
          </BtnBox>
        </ContentBox>
      </AlertRequest>
    </>
  );
}

const AlertRequest = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 4rem 0 25.4rem;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(3rem);
  -webkit-backdrop-filter: blur(3rem);
  text-align: center;
  overflow-y: auto;
  z-index: 200;
`;
const TitleBox = styled.div`
  color: #fff;
  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65.4rem;
    height: 10.8rem;
    margin: 5.4rem auto 0;
    border-radius: 5.4rem;
    background: rgba(255, 255, 255, 0.2);
    font-size: 4.2rem;
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 64.2rem;
  height: 16rem;
  margin: 0 auto;
  border-radius: 8rem;
  background: rgba(0, 0, 0, 0.4);
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.3;
  span {
    font-size: 4.8rem;
  }
`;
const ContentBox = styled.div`
  position: relative;
  width: 67.8rem;
  /* height: 79.4rem; */
  margin: 5.6rem auto 0;
  padding: 3rem 1rem 3rem;
  border-radius: 3rem;
  background-color: #fff;
  line-height: 1.3;
  overflow: hidden;
`;
const BtnNotify = styled.a`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 5.4rem;
`;
const ContTitle = styled.div`
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #707070;
  font-size: 4.2rem;
  font-weight: 700;
  span {
    font-size: 5.2rem;
  }
`;
const SubTitle = styled.p`
  margin-bottom: 3rem;
  font-size: 4.2rem;
`;
const SwiperRequest = styled.div`
  label {
    display: block;
  }
  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    + span {
      display: inline-block;
      width: 14rem;
      height: 13.1rem;
      line-height: 13.1rem;
      border-radius: 2.8rem;
      background: linear-gradient(-45deg, #f2f3f7, #e8ebf2);
      color: #767676;
    }
  }
  input[type="checkbox"]:checked {
    + span {
      background: linear-gradient(-45deg, #2a7da8, #007473);
      color: #bcbcbc;
    }
  }
`;
const Swiper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 4.2rem;
  font-weight: 700;
  overflow: hidden;
`;
const SwiperWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const SwiperSlide = styled.div`
  width: 14rem !important;
  margin: 1rem 1.1rem;
`;
const SwiperBtnPrev = styled.div`
  left: -4.4rem;
  transform: rotate(180deg) translateY(50%);
  width: 6rem;
  height: 6rem;
  margin: 0;
  background: #fff url("/image/ico_swiper_arrow.png") no-repeat center;
  background-size: 4rem;
  ::after {
    content: "";
  }
`;
const SwiperBtnNext = styled.div`
  right: -4.4rem;
  transform: translateY(-50%);
  width: 6rem;
  height: 6rem;
  margin: 0;
  background: #fff url("/image/ico_swiper_arrow.png") no-repeat center;
  background-size: 4rem;
  ::after {
    content: "";
  }
`;
const NotifyTxt = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18rem;
  color: #e12c03;
  font-size: 3.4rem;
  font-weight: 700;
`;
const BtnBox = styled.div`
  display: flex;
  justify-content: center;
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 19.4rem;
    height: 8rem;
    margin: 0 2.5rem;
    border-radius: 9.7rem;
    font-size: 4.2rem;
    font-weight: 700;
  }
`;
const BtnCancel = styled.button`
  border: 0.3rem solid #bcbcbc;
  color: #767676;
  box-shadow: inset 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
`;
const BtnConfirm = styled.button`
  background-color: #bcbcbc;
  color: #767676;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
`;
