import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { API, token } from "../../constants";
import ManageNavBar from "../manage/ManageNavigation";
import TimeTableHeader from "../timetable/TimetableHeader";
import TimetableDetailContent from "./TimetableDetailContent";
import TimetableUser from "./TimetableUser";

export default function TimetableDetailPage() {
  const [driverInfo, setDriverInfo] = useState([]);
  const [driverNumber, setDriverNumber] = useState("");

  const location = useLocation();
  const date = location.state.date;
  const confirmDate = date < 10 ? "0" + date : date;
  const linkDate = moment().format("YYYY-MM-" + confirmDate);
  const routeId = sessionStorage.getItem("routeId");

  const getDriver = async () => {
    const res = await axios.get(`${API}/route/driver/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "server-version": "2.0.2",
      },
    });
    setDriverInfo(res.data.object);
    setDriverNumber(res.data.object.busNumber.slice(-4));
  };

  const [status, setStatus] = useState("요약");
  const handleClick = (e) => {
    setStatus(e.target.name);
  };
  const [timetable, setTimetable] = useState([]);
  const getTimetable = async () => {
    const res = await axios.get(
      `${API}/dispatch/admin/${routeId}/${linkDate}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTimetable(res.data.object);
  };
  console.log(timetable);

  useEffect(() => {
    getDriver();
    getTimetable();
  }, []);

  return (
    <>
      <div id="wrap">
        <Container class="container timetable">
          <TimeTableHeader />
          <TimetableUser driverInfo={driverInfo} driverNumber={driverNumber} />
          <Inner>
            <TabList>
              <BtnTab
                onClick={handleClick}
                name="요약"
                className={status === "요약" ? "on" : ""}
              >
                요약
              </BtnTab>
              <BtnTab
                onClick={handleClick}
                name="상세"
                className={status === "상세" ? "on" : ""}
              >
                상세
              </BtnTab>
            </TabList>
            <TimetableDetailContent status = {status} timetable = {timetable} linkDate = {linkDate}/>
          </Inner>
          <ManageNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 17.5rem;
  overflow-y: hidden;
`;

const Inner = styled.div`
  height: calc(100% - 10rem);
  background: url("../image/bg_daily.jpg") no-repeat top center/contain;
`;
const TabList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 4.8rem;
  .on {
    border-color: #f2f3f7;
    background-color: #007473;
    color: #fff;
    box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.16);
  }
`;
const BtnTab = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31.4rem;
  height: 9rem;
  border: 0.3rem solid #767676;
  border-radius: 6.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(1.8rem);
  -webkit-backdrop-filter: blur(1.8rem);
  color: rgba(188, 188, 188, 0.5);
  font-size: 4.2rem;
  font-weight: 700;
  box-shadow: inset 1.4rem 1.4rem 3rem rgba(0, 0, 0, 0.34);
`;

