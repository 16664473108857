import styled from "styled-components";

export default function TimetableRoundUser ({ driverInfo,driverNumber}) {
    return(
      <>
            <UserBox class="user-box">
                <Logo class="logo">
                  <img src="/image/logo_bus.png" alt="" />
                </Logo>
                <h1>
                  <span>{driverInfo.branchName}</span>
                  <strong>
                    <span> {driverInfo.routeName}번 노선</span>
                  </strong>
                  <BusNum> {driverNumber} 차량</BusNum>
                </h1>
              </UserBox>
      </>
    )
}

const UserBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 13rem;
  padding: 0 0 0 12.6rem;
  h1 {
    font-size: 4.2rem;
    letter-spacing: -0.1px;
    > span {
      font-weight: 400;
    }
  }
`;
const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 4.8rem;
  transform: translateY(-50%);
  width: 7rem;
`;
const BusNum = styled.span`
  font-size: 3.4rem;
`;