import { useEffect, useState } from "react";
import styled from "styled-components";
import ManageHeader from "./ManageHeader";
import ManageNavBar from "./ManageNavigation";
import axios from "axios";
import { API, token } from "../../constants";
import UserInfo from "./UserInfo";
import { Link } from "react-router-dom";

export default function ManagePage() {
  const [driverInfo, setDriverInfo] = useState([]);
  const [driverNumber, setDriverNumber] = useState("");
  const getDriver = async () => {
    const res = await axios.get(`${API}/route/driver/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "server-version": "2.0.2",
      },
    });
    setDriverInfo(res.data.object);
    setDriverNumber(res.data.object.busNumber.slice(-4));
    window.sessionStorage.setItem("routeId", res.data.object.routeId);
  };

  console.log(driverInfo);
  useEffect(() => {
    getDriver();
  }, []);

  return (
    <>
      <div id="wrap">
        <Container class="container management">
          <ManageHeader />
          <Inner class="inner">
            <UserInfo driverInfo={driverInfo} driverNumber={driverNumber} />
            <MenuList class="menu-list">
              <CellType class="type-cell">
                <Link to={"/schedule"}>
                  <span class="menu-title">
                    근무
                    <br />
                    일정표
                  </span>

                  <IconBox class="icon-box">
                    <AlarmImg class="ico-alarm"></AlarmImg>
                    <CalendarImg class="ico-calendar"></CalendarImg>
                  </IconBox>

                  <span class="notify-txt" id="month"></span>
                </Link>
              </CellType>
              <CellType class="type-cell">
                <Link to={"/condition"}>
                    <span class="menu-title">
                      신청
                      <br />
                      현황
                    </span>
  
                    <IconBox class="icon-box">
                      <AlarmImg class="ico-alarm"></AlarmImg>
                      <RequestImg class="ico-request"></RequestImg>
                    </IconBox>
                </Link>
              </CellType>
              <RowType class="type-row chk-day">
                <a href="schedule_check.html" class="btn-common btn-disabled">
                  <span class="menu-title">근무일정 확인</span>
                  {/* <!-- <span class="date" id="date1">
                            
                        </span> --> */}

                  <IconBox class="icon-box">
                    <AlarmImg class="ico-alarm on"></AlarmImg>
                  </IconBox>
                </a>
              </RowType>
                <RowType class="type-row chk-timetable">
             <Link to={"/timetable_check"}>
                    <span class="menu-title">배차일보 조회</span>
                    {/* <!-- <span class="date" id="date2">
                              
                          </span> --> */}
  
                    <IconBox class="icon-box">
                      <AlarmImg class="ico-alarm"></AlarmImg>
                    </IconBox>
             </Link>
                </RowType>
            </MenuList>

            <CsBox class="cs-box">
              <button class="btn-cs">
                <img src="/image/ico_kikii_cs.png" alt="키키아이" />
              </button>
            </CsBox>
          </Inner>
          <ManageNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  position: relative;
  max-width: 750px;
  margin: 0 auto;
`;
const Inner = styled.div`
  background: #f2f3f7 url("/image/bg_daily02.jpg") no-repeat top center/contain;
`;

const MenuList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4.2rem 4.8rem 39.6rem;
  border-top-right-radius: 6rem;
  border-top-left-radius: 6rem;
  background-color: #f2f3f7;
  font-weight: 700;
  li {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2rem;
    background: linear-gradient(-45deg, #f2f3f7, #e8ebf2);
    box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
    font-size: 5.2rem;
  }
  li:first-child {
    border-bottom-right-radius: 5rem;
  }
  li:nth-child(2) {
    border-bottom-left-radius: 5rem;
  }
  li:nth-child(2) ~ li {
    margin-top: 4.8rem;
  }
  li:last-child {
    margin-top: 3.6rem;
  }
  span {
    line-height: 1.2;
  }
  a {
    display: block;
    height: 100%;
    padding: 3.4rem 4.4rem;
  }
`;

const BtnDisabled = styled.button`
  color: #bcbcbc;
  pointer-events: none;
  ::after {
    content: "준비중";
    position: absolute;
    bottom: 2.5rem;
    right: 2rem;
    color: #007473;
    font-size: 2.8rem;
  }
`;
const IconBox = styled.div`
  position: absolute;
  top: 3rem;
  right: 2rem;
  i {
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 5.2rem;
  }
`;
const AlarmImg = styled.i`
  background-image: url("../image/ico_alarm_off.png");
  background-size: 6.4rem;
`;
const CalendarImg = styled.i`
  background-image: url("../image/ico_calendar.png");
`;
const RequestImg = styled.i`
  background-image: url("../image/ico_request.png");
`;
const CellType = styled.li`
  position: relative;
  width: 30.4rem;
  height: 25.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 2rem;
  background: linear-gradient(-45deg, #f2f3f7, #e8ebf2);
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  font-size: 5.2rem;
  :nth-child(2n) {
    text-align: right;
    div {
      position: absolute;
      top: 3rem;
      left: 2rem;
    }
  }
`;
const RowType = styled.li`
  width: 100%;
  height: 16rem;
  line-height: 1.8;
  font-size: 4.8rem;
  i {
    display: inline-block;
    vertical-align: middle;
    + i {
      margin-left: 3.4rem;
    }
  }
`;
const CsBox = styled.div`
  position: fixed;
  bottom: 17.6rem;
  right: calc(50% - 32rem);
  button {
    display: block;
    width: 12rem;
    border-radius: 50%;
    box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.3);
  }
`;
