import styled from "styled-components";
import MainHeader from "./MainHeader";
import MainNavBar from "./MainNavigation";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../../constants";
import { useEffect, useState } from "react";
import moment from "moment";

export default function MainPage() {
  const token = sessionStorage.getItem("token");
  const [userInfo, setUserInfo] = useState([]);
  const dayArr = [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일",
  ];
  const today = moment().format("MM월DD일");
  const day = moment().day();
  const time = moment().format("hh:mm");
  const dayNight = moment().format("A");
  const getUser = async () => {
    const res = await axios.get(`${API}/user-info`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setUserInfo(res.data.object);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div id="wrap">
        <Container>
          <MainHeader />
          <UserBox class="user-box" id="user">
            <Logo class="logo">
              <img src="/image/logo_bus.png" alt="" />
            </Logo>
            <h1>
              {userInfo.companyName}
              <strong> {userInfo.name} 님</strong>
            </h1>

            <BtnTimeTable href="#" class="btn-timetable">
              출근부
            </BtnTimeTable>
          </UserBox>

          <Inner class="inner">
            <DailyBox class="daily-box">
              <BoxInner class="box-inner">
                <ItemDate class="item date">
                  <First class="first">{today}</First>
                  <Second class="second">{dayArr[day]}</Second>
                </ItemDate>
                <ItemClock class="item clock">
                  <First class="first">{dayNight}</First>
                  <ClockSecond class="second">{time}</ClockSecond>
                </ItemClock>
              </BoxInner>
            </DailyBox>

            <MenuList>
              <CellType class="type-cell">
                <Link to="/manage">
                  <section href="management.html" class="btn-common">
                    <span class="menu-title">
                      근무
                      <br />
                      일정
                    </span>

                    <IconBox class="icon-box">
                      <AlarmImg class="ico-alarm on"></AlarmImg>
                      <CalenderImg class="ico-calendar"></CalenderImg>
                    </IconBox>
                  </section>
                </Link>
              </CellType>
              <CellType class="type-cell">
                <Link to="/timetable">
                  <section>
                    <span class="menu-title">
                      배차
                      <br />
                      일보
                    </span>

                    <IconBox class="icon-box">
                      <AlarmImg class="ico-alarm"></AlarmImg>
                      <BusRunImg class="ico-bus-run"></BusRunImg>
                    </IconBox>
                  </section>
                </Link>
              </CellType>
              <CellTypeNone class="type-cell">
                <BtnDisabled class="btn-common btn-disabled">
                  <span class="menu-title">
                    차량
                    <br />
                    관리
                  </span>

                  <IconBox class="icon-box">
                    <UpdateImg class="ico-update"></UpdateImg>
                    <BusAdminImg class="ico-bus-admin"></BusAdminImg>
                  </IconBox>
                </BtnDisabled>
              </CellTypeNone>
              <CellTypeNone class="type-cell">
                <BtnDisabledRight class="btn-common btn-disabled">
                  <span class="menu-title">
                    운행
                    <br />
                    분석
                  </span>

                  <IconBox class="icon-box">
                    <AlarmImg class="ico-alarm"></AlarmImg>
                    <AnalysisImg class="ico-analysis"></AnalysisImg>
                  </IconBox>
                </BtnDisabledRight>
              </CellTypeNone>
              <RowType class="type-row">
               <Link to={"/notice"}>
                  <section>
                    <span class="menu-title">공지사항</span>
                    <IconBox class="icon-box">
                      <AlarmImg class="ico-alarm"></AlarmImg>
                      <NoticeImg class="ico-notice"></NoticeImg>
                    </IconBox>
                  </section>
               </Link>
              </RowType>
              <RowTypeNone class="type-row">
                <BtnDisabledDown class="btn-common btn-disabled">
                  <span class="menu-title">분실물 관리</span>

                  <IconBox class="icon-box">
                    <UpdateImg class="ico-update"></UpdateImg>
                    <LostItemImg class="ico-lost-item"></LostItemImg>
                  </IconBox>
                </BtnDisabledDown>
              </RowTypeNone>
            </MenuList>
          </Inner>
          <MainNavBar />
        </Container>
      </div>
    </>
  );
}

const UserBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 13rem;
  padding: 0 21rem 0 12.6rem;
  h1 {
    font-size: 4.2rem;
    font-weight: 400;
    letter-spacing: -1px;
  }
`;
const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 4.8rem;
  transform: translateY(-50%);
  width: 7rem;
`;
const BtnTimeTable = styled.a`
  position: absolute;
  top: 50%;
  right: 4.8rem;
  transform: translateY(-50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20.8rem;
  height: 7.4rem;
  border-radius: 3.7rem;
  background-color: #007473;
  color: #fff;
  font-size: 3.4rem;
  font-weight: 700;
  box-shadow: 0.3rem 0.3rem 2.4rem rgba(0, 0, 0, 0.1);
`;
const Inner = styled.div`
  background: url("/image/bg_daily.jpg") no-repeat top center/contain;
`;
const DailyBox = styled.div`
  padding: 3.5rem 0;
`;
const BoxInner = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 65.4rem;
  height: 12.4rem;
  margin: 0 auto;
  padding: 0 4.6rem;
  border: 0.3rem solid rgba(255, 255, 255, 0.24);
  border-radius: 6.2rem;
  background-color: rgba(255, 255, 255, 0.4);
  line-height: 1.2;
`;
const Item = styled.div`
  position: relative;
  height: 8.2rem;
  width: 13.4rem;
  padding: 0 2.4rem;
  text-align: center;
  white-space: nowrap;
  box-sizing: content-box;
`;
const ItemDate = styled(Item)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 14.4rem;
  font-size: 3.4rem;
`;
const ItemClock = styled(Item)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 3.4rem;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: -4rem;
    width: 0.2rem;
    height: 8.2rem;
    background-color: #000;
  }
`;
const First = styled.span`
  padding-top: 0.2rem;
  line-height: 1;
`;
const Second = styled.strong`
  padding-bottom: 0.2rem;
  line-height: 1;
`;
const ClockSecond = styled(Second)`
  font-size: 3.8rem;
`;

const MenuList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4.2rem 4.8rem 21rem;
  border-top-right-radius: 6rem;
  border-top-left-radius: 0.8rem;
  background-color: #f2f3f7;
  font-weight: 700;
  li {
    :first-child {
      border-bottom-right-radius: 5rem;
    }
    :nth-child(2) {
      border-bottom-left-radius: 5rem;
    }
    :nth-child(3) {
      border-top-right-radius: 5rem;
    }
    :nth-child(4) {
      border-top-left-radius: 5rem;
    }
    :nth-child(2) ~ li {
      margin-top: 4.8rem;
    }
    :last-child {
      margin-top: 3.6rem;
    }
    span {
      line-height: 1.2;
    }
    section {
      display: block;
      height: 100%;
      padding: 3.4rem 4.4rem;
    }
  }
`;
const Container = styled.div`
  position: relative;
  max-width: 750px;
  margin: 0 auto;
`;
const CellType = styled.li`
  position: relative;
  width: 30.4rem;
  height: 25.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 2rem;
  background: linear-gradient(-45deg, #f2f3f7, #e8ebf2);
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  font-size: 5.2rem;
  :nth-child(2n) {
    text-align: right;
    div {
      position: absolute;
      top: 3rem;
      left: 2rem;
    }
  }
`;
const CellTypeNone = styled(CellType)`
  color: #bcbcbc;
`;
const IconBox = styled.div`
  position: absolute;
  top: 3rem;
  right: 2rem;
  i {
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: 5.2rem; */
  }
`;
const RowType = styled.li`
  position: relative;
  width: 100%;
  height: 12.4rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 2rem;
  background: linear-gradient(-45deg, #f2f3f7, #e8ebf2);
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  font-size: 4.8rem;
  i {
    display: inline-block;
    vertical-align: middle;
    + i {
      margin-left: 3.4rem;
    }
  }
`;
const RowTypeNone = styled(RowType)`
  color: #bcbcbc;
`;
const BtnDisabled = styled.section`
  color: #bcbcbc;
  pointer-events: none;
  ::after {
    content: "준비중";
    position: absolute;
    bottom: 2.5rem;
    right: 2rem;
    color: #007473;
    font-size: 2.8rem;
  }
`;
const BtnDisabledRight = styled.section`
  color: #bcbcbc;
  pointer-events: none;
  ::after {
    content: "준비중";
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    color: #007473;
    font-size: 2.8rem;
  }
`;
const BtnDisabledDown = styled.section`
  pointer-events: none;
  ::after {
    content: "준비중";
    position: static;
    display: inline-block;
    margin-left: 2rem;
    color: #007473;
    font-size: 2.8rem;
  }
`;
const AlarmImg = styled.i`
  background-image: url("/image/ico_alarm_off.png");
  background-size: 6.4rem;
`;
const CalenderImg = styled.i`
  background-image: url("/image/ico_calendar.png");
  background-size: 5.2rem;
`;
const BusRunImg = styled.i`
  background-image: url("/image/ico_bus_run.png");
  background-size: 5.2rem;
`;
const UpdateImg = styled.i`
  background-image: url("/image/ico_update_on.png");
  background-size: 6.4rem;
`;
const BusAdminImg = styled.i`
  background-image: url("/image/ico_bus_admin.png");
  background-size: 5.2rem;
`;
const AnalysisImg = styled.i`
  background-image: url("/image/ico_analysis.png");
  background-size: 5.2rem;
`;
const NoticeImg = styled.i`
  background-image: url("/image/ico_notice.png");
  background-size: 5.2rem;
`;
const LostItemImg = styled.i`
  background-image: url("/image/ico_lost_item.png");
  background-size: 5.2rem;
`;
