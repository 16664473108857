import SchedulePage from "../components/schedule/SchedulePage";



export default function Schedule() {
    return (
        <>
            <SchedulePage />
        </>
    )
}