import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { API, token } from "../../constants";
import NoticeNavBar from "../notice/NoticeNavigation";
import NoticeDetailHeader from "./NoticeDetailHeader";

export default function NoticeDetailPage() {
  const [likeCount, setLikeCount] = useState(0);
  const [likeIcon, setLikeIcon] = useState(false);
  const clickLike = () => {
    setLikeIcon(!likeIcon);
    if (likeIcon !== true) {
      setLikeCount(likeCount + 1);
    } else {
      setLikeCount(likeCount - 1);
    }
  };
  const location = useLocation();
  const noticeId = location.state.id;
  console.log(noticeId);
  const [noticeDetail, setNoticeDetail] = useState();
  const getNoticeDetail = async () => {
    const res = await axios.get(`${API}/notice/${noticeId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res);
    setNoticeDetail(res.data.object);
  };
  useEffect(() => {
    getNoticeDetail();
  }, []);
  return (
    <>
      <div id="wrap">
        <Container class="container notice-detail">
          <NoticeDetailHeader />

          <div class="img-box">
            <img src="/image/bg_notice_detail.jpg" alt="" />
          </div>

          <Inner class="inner">
            <Company class="company">
              <ImgBox class="img-box">
                <img src="/image/logo_kikii.jpg" alt="" />
              </ImgBox>
              <TxtBox class="txt-box">
                <strong>키키아이</strong>
                <p>키키아이 연구소</p>
              </TxtBox>
              <LikeBox className={likeIcon ? "on" : ""} onClick={clickLike}>
                <span class="btn-like">{likeCount}</span>
              </LikeBox>
            </Company>

            <DetailBox>
              <p className="title">{noticeDetail?.title}</p>
              <ul className="info">
                <li>02월14일</li>
                <li>12:00</li>
                <li>조회 {noticeDetail?.hit}</li>
              </ul>
              <Desc class="desc">{noticeDetail?.contents}</Desc>
            </DetailBox>
          </Inner>

          <NoticeNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
`;
const Inner = styled.div`
  padding: 0 5rem 21.5rem;
  background-color: #f2f3f7;
`;
const Company = styled.div`
  position: relative;
  display: flex;
  padding: 4.8rem 0;
  border-bottom: 1px solid #bcbcbc;
  .on {
    position: absolute;
    top: 5.3rem;
    right: 0;
    line-height: 5.4rem;
    color: #767676;
    font-size: 3.2rem;
    background: url("/image/ico_like_on.png") no-repeat left center;
    background-size: 5.4rem;
  }
`;
const ImgBox = styled.div`
  width: 12rem;
  margin-right: 4.4rem;
  img {
    border-radius: 50%;
  }
`;
const TxtBox = styled.div`
  align-self: center;
  strong {
    font-size: 3.8rem;
  }
  p {
    padding-top: 1rem;
    font-size: 3.2rem;
  }
`;
const LikeBox = styled.div`
  position: absolute;
  top: 5.3rem;
  right: 0;
  line-height: 5.4rem;
  color: #767676;
  font-size: 3.2rem;
  background: url("/image/ico_like_off.png") no-repeat left center;
  background-size: 5.4rem;
  span {
    display: block;
    padding-left: 7.4rem;
  }
`;
const DetailBox = styled.div`
  overflow-y: scroll;
  height: 78rem;
  padding: 5rem 0;
  .title {
    margin-bottom: 2.5rem;
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 1.2;
    word-break: keep-all;
  }
  .info {
    display: flex;
    padding-bottom: 6rem;
    color: #767676;
    font-size: 2.8rem;
    li + li {
      margin-left: 1.5rem;
      padding-left: 1.5rem;
      border-left: 0.2rem solid #767676;
    }
  }
`;
const Desc = styled.div`
  font-size: 3.4rem;
  line-height: 1.5;
  word-break: keep-all;
`;
