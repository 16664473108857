import { format } from "date-fns";
import styled from "styled-components";

export default function RenderHeader({ currentMonth, prevMonth, nextMonth }) {
    return (
        <CalendarHeader class="calendar-header">
        <PrevBtn onClick={prevMonth}>
          <img src="/image/ico_calendar_prev.png" alt="이전달" />
        </PrevBtn>
        <NextBtn onClick={nextMonth}>
          <img src="/image/ico_calendar_next.png" alt="다음달" />
        </NextBtn>
        <span class="title" id="year_month">
          {format(currentMonth,'yyyy')}년 <strong>{format(currentMonth, 'M')}월</strong>
        </span>
      </CalendarHeader>
    )
}

const CalendarHeader = styled.div`
  position: relative;
  height: 13.6rem;
  padding: 0 6rem;
  text-align: center;
  span {
    color: #fff;
    font-size: 5.2rem;
    font-weight: 400;
    line-height: 13.6rem;
  }
`;
const PrevBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6.2rem;
  height: 6.2rem;
  left: 5.8rem;
`;
const NextBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6.2rem;
  height: 6.2rem;
  right: 5.8rem;
`;