import styled from "styled-components";
import ManageNavBar from "../manage/ManageNavigation";
import TimetableCalendar from "./TimetableCalendar";
import TimetableScheduleHeader from "./TimetableScheduleHeader";

export default function TimetableCheckPage() {
  return (
    <>
      <div id="wrap" class="scroll-hidden">
        <Container class="container schedule">
          <TimetableScheduleHeader />
          <TimetableCalendar />
          <ManageNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;

