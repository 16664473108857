import ManagePage from "../components/manage/ManagePage";



export default function Manage() {
    return (
        <>
            <ManagePage />
        </>
    )
}