import styled from "styled-components";
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek } from "date-fns";
import { isSameMonth, isSameDay, addDays, format, subDays } from "date-fns";
import WorkDays from "./WorkDays";
import { useNavigate } from "react-router-dom";

export default function RenderCells({
  currentMonth,
  selectedDate,
  workDay,
}) {
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = subDays(startOfWeek(monthStart), -1);
  const endDate = endOfWeek(monthEnd);

  const navigate = useNavigate();
  const handleClickDate = (e) => {
    const selectDate = e.target.innerText;
    navigate("/timetable_detail", {
      state: {
        date: selectDate,
      },
    });
  };
  // console.log(selectDate);
  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = "";

  const dateArr = [];
  if (workDay.length > 1) {
    workDay.map((v) => {
      let dateDay = v.date.slice(-2);
      if (dateDay < 10) {
        dateDay = dateDay.slice(-1);
      }
      dateArr.push({
        date: dateDay,
        status: v.status,
      });
    });
  }
  // console.log(dateArr);

  let workCount = 0;
  let leaveCount = 0;
  let annualCount = 0;
  let status = "";
  let nameClass = "";

  workDay.forEach((v) => {
    if (v.status.includes("WORK")) {
      workCount = workCount + 1;
    }
    if (v.status.includes("LEAVE")) {
      leaveCount = leaveCount + 1;
    }
    if (v.status.includes("ANNUAL")) {
      annualCount = annualCount + 1;
    }
  });

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, "d");
      for (let j = 0; j < dateArr.length; j++) {
        // console.log(j);
        status = "";
        nameClass = "";
        if (formattedDate === dateArr[j].date) {
          status = dateArr[j].status;
          if (status === "WORK") {
            nameClass = "day-work";
          } else if (status === "LEAVE") {
            nameClass = "day-off";
          } else {
            nameClass = "type-leave";
          }
          break;
        } else {
          nameClass = "";
          status = "";
        }
        // console.log(status ,nameClass, formattedDate);
      }
      days.push(
        <div key={day}>
          <span
            onClick={handleClickDate}
            className={`${
              !isSameMonth(day, monthStart)
                ? "day-num"
                : isSameDay(day, selectedDate)
                ? "day-today"
                : format(currentMonth, "M") !== format(day, "M")
                ? "not-valid"
                : nameClass
            }`}
          >
            {formattedDate}
          </span>
        </div>
      );
      // console.log(nameClass);
      day = addDays(day, 1);
    }
    rows.push(<TableRow key={day}>{days}</TableRow>);
    days = [];
  }

  return (
    <TableBody>
      <div>{rows}</div>
      <WorkDays
        workCount={workCount}
        leaveCount={leaveCount}
        annualCount={annualCount}
      />
    </TableBody>
  );
}

const TableBody = styled.div`
  height: calc(100% - 9rem);
  border-radius: 1.2rem 1.2rem 0.8rem 0.8rem;
  background-color: #f2f3f7;
  box-shadow: 0 -1rem 1.2rem rgba(0, 0, 0, 0.02);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const TableRow = styled.div`
  height: 16rem;
  background: url("../image/bg_calendar_week.png") repeat-y top 1rem center;
  background-size: 73rem 16rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2.5rem 2.5rem 1rem;
  div {
    width: 3rem;
    span {
      position: relative;
      padding: 1.3rem 0 1.8rem;
      color: #bcbcbc;
      font-size: 3.8rem;
      text-align: center;
    }
    .day-num {
      font-size: 3rem;
      font-weight: 400;
    }
    .day-work {
      color: #000;
    }
    .day-today {
      color: #007473;
      ::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 4.4rem;
        height: 0.6rem;
        border-radius: 0.6rem;
        background-color: #007473;
      }
    }
    .day-off {
      color: #e12c03;
      .type-leave {
        ::after {
          content: "연차";
          position: absolute;
          bottom: -1.5rem;
          left: 50%;
          transform: translateX(-50%);
          color: #e12c03;
          font-size: 2.6rem;
          white-space: nowrap;
        }
      }
    }
  }
`;
