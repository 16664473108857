import axios from "axios";
import { addMonths, format, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { API, token } from "../../constants";
import ManageHeader from "../manage/ManageHeader";
import ManageNavBar from "../manage/ManageNavigation";
import ExchangeDeleteModal from "./ExchangeDeleteModal";
import Result from "./Result";

export default function ConditionExchangePage() {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };
  const thisMonth = format(currentMonth, "yyyy-MM");
  console.log(thisMonth);
  const [status, setStatus] = useState("교환");
  const [request, setRequest] = useState([]);
  const [result, setResult] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleOffPopup = () => {
    setDeletePopup(false);
  };

  const handleClick = (e) => {
    setStatus(e.target.name);
  };
  const getRequest = async () => {
    const res = await axios.get(`${API}/replace/${thisMonth}`, {
      headers: {
        "Content-Type": "application/json",
        "server-version": "2.1.0",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res);
    setRequest(res.data.object.request);
    setResult(res.data.object.result);
  };
  console.log(request);
  useEffect(() => {
    getRequest();
  }, [thisMonth, deletePopup]);

  return (
    <>
      <div id="wrap">
        <Container>
          <ManageHeader />
          <CalendarWrap>
            <CalendarHeader>
              <PrevBtn onClick={prevMonth}>
                <img src="/image/ico_calendar_prev.png" alt="이전달" />
              </PrevBtn>
              <NextBtn onClick={nextMonth}>
                <img src="/image/ico_calendar_next.png" alt="다음달" />
              </NextBtn>
              <span class="title" id="year_month">
                {format(currentMonth, "yyyy")}년{" "}
                <strong>{format(currentMonth, "M")}월</strong>
              </span>
            </CalendarHeader>
            <ConditionWrap>
              <TabList>
                <BtnTab
                  onClick={handleClick}
                  name="교환"
                  className={status === "교환" ? "on" : ""}
                >
                  휴무 교환
                </BtnTab>
                <BtnTab
                  onClick={handleClick}
                  name="결과"
                  className={status === "결과" ? "on" : ""}
                >
                  처리결과
                </BtnTab>
              </TabList>
              <CurrentCont class="current-content">
                {status === "교환" ? (
                  <Cont class="content content01 on">
                    {request.map((data) => {
                      const handleOnPopup = () => {
                        setDeleteId(data.replaceId);
                        setDeletePopup(true);
                        console.log(deleteId);
                      };
                      return (
                        <ListRow class="list-row">
                          <DateList className="date">
                            <strong>
                              {data?.createdAt
                                .replaceAll("-", ".")
                                .slice(0, 10)}
                            </strong>
                            <span>{data?.createdAt.slice(11, 16)}</span>
                          </DateList>

                          <Item>
                            <div className="desc">
                              <span>{data.reqDriverName}</span>
                              <br />
                              <strong>
                                {data?.reqDriverLeaveDate.slice(-2) + "일"}
                              </strong>
                            </div>
                            {data.resDriverStatus !== "REQUEST" ? (
                              <div className="status red">
                                합의
                                <br />
                                대기
                              </div>
                            ) : (
                              <div className="status">
                                승인
                                <br />
                                대기
                              </div>
                            )}
                            <div className="desc">
                              <span>{data.resDriverName}</span>
                              <br />
                              <strong>
                                {data?.reqDriverWorkDate.slice(-2) + "일"}
                              </strong>
                            </div>

                            <DeleteBtn onClick={handleOnPopup}>
                              <img src="/image/ico_delete.png" alt="삭제버튼" />
                            </DeleteBtn>
                          </Item>
                        </ListRow>
                      );
                    })}
                    {deletePopup ? (
                      <ExchangeDeleteModal
                        deleteId={deleteId}
                        handleOffPopup={handleOffPopup}
                        setDeletePopup={setDeletePopup}
                      />
                    ) : null}
                  </Cont>
                ) : (
                  <Cont class="content content01 on">
                    {result.map((data) => {
                      return (
                        <ListRow class="list-row">
                          <DateList className="date">
                            <strong>
                              {data?.updatedAt
                                .replaceAll("-", ".")
                                .slice(0, 10)}
                            </strong>
                            <span>{data?.updatedAt.slice(11, 16)}</span>
                          </DateList>
                          <ResultItem>
                            <div className="desc">
                              <span>{data.reqDriverName}</span>
                              <br />
                              <strong>
                                {data?.reqDriverLeaveDate.slice(-2) + "일"}
                              </strong>
                            </div>
                            <Result data = {data}/>
                            <div className="desc">
                              <span>{data.resDriverName}</span>
                              <br />
                              <strong>
                                {data?.reqDriverWorkDate.slice(-2) + "일"}
                              </strong>
                            </div>
                          </ResultItem>
                        </ListRow>
                      );
                    })}
                  </Cont>
                )}
                {/* 연차신청 */}
              </CurrentCont>
            </ConditionWrap>
          </CalendarWrap>
          <ManageNavBar />
        </Container>
      </div>
    </>
  );
}

const CalendarHeader = styled.div`
  position: relative;
  height: 13.6rem;
  padding: 0 6rem;
  text-align: center;
  span {
    color: #fff;
    font-size: 5.2rem;
    font-weight: 400;
    line-height: 13.6rem;
  }
`;
const PrevBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6.2rem;
  height: 6.2rem;
  left: 5.8rem;
`;
const NextBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6.2rem;
  height: 6.2rem;
  right: 5.8rem;
`;

const Container = styled.div`
  height: 100vh;
  background-color: #f2f3f7;
  position: relative;
  max-width: 750px;
  margin: 0 auto;
`;
const CalendarWrap = styled.div`
  height: calc(100% - 24.4rem);
  background-image: url(/image/bg_calendar_jan.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  background-color: #f2f3f7;
`;
const ConditionWrap = styled.div`
  height: calc(100% - 13.6rem);
  border-top-right-radius: 6rem;
  border-top-left-radius: 0.8rem;
  background-color: #f2f3f7;
  overflow: hidden;
`;
const TabList = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 4.8rem;
  .on {
    border-color: #f2f3f7;
    background-color: #007473;
    color: #fff;
    box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.16);
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 66rem;
    height: 0.4rem;
    background-color: #007473;
    box-shadow: 0.4rem 0.4rem 0.8rem rgb(15 41 107 / 12%);
  }
`;
const BtnTab = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31.4rem;
  height: 9rem;
  border: 0.3rem solid #767676;
  border-radius: 6.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(1.8rem);
  -webkit-backdrop-filter: blur(1.8rem);
  color: rgba(188, 188, 188, 0.5);
  font-size: 4.2rem;
  font-weight: 700;
  box-shadow: inset 1.4rem 1.4rem 3rem rgb(0 0 0 / 34%);
`;
const CurrentCont = styled.div`
  height: calc(100% - 16rem);
  overflow: hidden;
`;
const Cont = styled.div`
  /* display: none; */
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const ListRow = styled.div`
  position: relative;
  margin-top: 3.5rem;
  .approver {
    position: absolute;
    top: 0;
    right: 7rem;
    width: 25rem;
    font-size: 2.8rem;
    line-height: 4.2rem;
    text-align: center;
  }
  .date {
    background-color: rgba(25, 25, 25, 0.3);
  }
  .result-date {
    background-color: rgba(25, 25, 25, 0.46);
  }
`;
const DateList = styled.div`
  width: 35.5rem;
  margin-left: 7.2rem;
  line-height: 4.2rem;
  border-radius: 21px;
  color: #fff;
  font-size: 2.8rem;
  text-align: center;
  letter-spacing: 0;
  strong + span {
    margin-left: 2rem;
  }
`;
const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 66rem;
  height: 13.4rem;
  margin: 1rem auto 2.2rem;
  padding: 0 8rem 0 4rem;
  border: 0.4rem solid #fff;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  font-size: 3.4rem;
  line-height: 1.2;
  box-shadow: 2rem 2rem 3rem rgb(15 41 107 / 12%);
  text-align: center;
  .title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 42%;
    font-size: 3.4rem;
    font-weight: 500;
  }

  .status {
    width: 26%;
    text-align: center;
    position: relative;
    color: #007473;
    font-size: 3rem;
    font-weight: 700;
    ::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 5.2rem;
      height: 5.2rem;
      right: -1.2rem;
      transform: translateY(-50%);
      background: url("/image/ico_condition_arrow.png") no-repeat center/100%;
    }
    ::before {
      content: "";
      left: -1.2rem;
      transform: rotate(180deg) translateY(50%);
      position: absolute;
      top: 50%;
      width: 5.2rem;
      height: 5.2rem;
      background: url("/image/ico_condition_arrow.png") no-repeat center/100%;
    }
  }
  .red {
    color: red;
  }

  .desc {
    width: 37%;
    span {
      font-size: 3.4rem;
    }
    strong {
      font-size: 3.2rem;
    }
  }
`;
const ResultItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 66rem;
  height: 13.4rem;
  margin: 1rem auto 2.2rem;
  padding: 0 4rem;
  border: 0.4rem solid #fff;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  font-size: 3.4rem;
  line-height: 1.2;
  box-shadow: 2rem 2rem 3rem rgb(15 41 107 / 12%);
  text-align: center;
  .title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 42%;
    font-size: 3.4rem;
    font-weight: 500;
  }

  .status {
    width: 26%;
    text-align: center;
    position: relative;
    color: #007473;
    font-size: 3.4rem;
    font-weight: 700;
    ::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 5.2rem;
      height: 5.2rem;
      right: -1.2rem;
      transform: translateY(-50%);
      background: url("/image/ico_condition_arrow.png") no-repeat center/100%;
    }
    ::before {
      content: "";
      left: -1.2rem;
      transform: rotate(180deg) translateY(50%);
      position: absolute;
      top: 50%;
      width: 5.2rem;
      height: 5.2rem;
      background: url("/image/ico_condition_arrow.png") no-repeat center/100%;
    }
  }
  .red {
    color: red;
  }

  .desc {
    width: 37%;
    span {
      font-size: 3.4rem;
    }
    strong {
      font-size: 3.2rem;
    }
  }
`;
const DeleteBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.8rem;
  width: 6rem;
  height: 6rem;
`;
