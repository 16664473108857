import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function MainNavBar() {
  const navigate = useNavigate();
  return (
    <>
    {/* 클래스 추가 제거 모드 */}
      <Nav>
        <NavList>
          <li className="on">
            <div onClick={() => {navigate('/home')}}>
              <div>
                <span className="blind">홈</span>
              </div>
            </div>
          </li>
          <li>
            <div onClick={() => {navigate('/manage')}}>
              <div>
                <span className="blind">근무관리</span>
              </div>
            </div>
          </li>
          <li>
            <div onClick={() => {navigate('/timetable')}}>
              <div>
                <span className="blind">배차일보</span>
              </div>
            </div>
          </li>
          <li>
            <div onClick={() => {navigate('/home')}}>
              <div>
                <span className="blind">사용자 설정</span>
              </div>
            </div>
          </li>
        </NavList>
      </Nav>
    </>
  );
}

const Nav = styled.nav`
  z-index: 160;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 750px;
  height: 14.4rem;
  padding: 2rem 8rem 0;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
  background-color: #191919;
`;
const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  li {
    width: 7.2rem;
    height: 7.2rem;
    background-repeat: no-repeat;
    background-position: center;
    :first-child {
      background-image: url("/image/ico_nav_home_off.png");
      background-size: 7.2rem;
    }
    :nth-child(2) {
      background-image: url("/image/ico_nav_calendar_off.png");
      background-size: 7.2rem;
    }
    :nth-child(3) {
      background-image: url("/image/ico_nav_bus_off.png");
      background-size: 8rem;
    }
    :last-child {
      background-image: url("/image/ico_nav_setting_off.png");
      background-size: 6.4rem;
    }
    :first-child.on {
      background-image: url("/image/ico_nav_home_on.png");
      background-size: 7.2rem;
    }
    :nth-child(2).on {
      background-image: url("/image/ico_nav_calendar_on.png");
      background-size: 7.2rem;
    }
    :nth-child(3).on {
      background-image: url("/image/ico_nav_bus_on.png");
      background-size: 8rem;
    }
    :last-child.on {
      background-image: url("/image/ico_nav_setting_on.png");
      background-size: 6.4rem;
    }
    div {
      display: block;
      height: 100%;
    }
  }
`;
