import { useState } from "react";
import styled from "styled-components";

export default function SelectCompany({ companyList, setCompanyCode }) {
  const [showCompany, setShowCompany] = useState(false);
  const [currentCompany, setCurrentCompany] = useState("회사명");

  const handleShowCompany = () => {
    setShowCompany((prev) => !prev);
  };

  const handleCurrentCompany = (e) => {
    const innerText = e.target.getAttribute("value");
    const selectCompany = e.target.getAttribute("name");
    setCompanyCode(selectCompany);
    setCurrentCompany(innerText);
    console.log(e.target.getAttribute("value"));
  };

  return (
    <>
      <SelectBox onClick={handleShowCompany}>
        <BtnSelect show={showCompany}>{currentCompany}</BtnSelect>
        <OptionListOn show={showCompany}>
          {companyList.map((data) => {
            return (
              <li
                key={data.name}
                onClick={handleCurrentCompany}
                value={data.name}
                name={data.code}
              >
                {data.name}
              </li>
            );
          })}
        </OptionListOn>
      </SelectBox>
    </>
  );
}

const SelectBox = styled.div`
  position: relative;
  width: 45.2rem;
`;
const BtnSelect = styled.a`
  z-index: 3;
  position: relative;
  display: flex;
  width: 29.8rem;
  height: 7.8rem;
  line-height: 7.8rem;
  margin: 0 auto;
  padding-left: 7rem;
  border: ${(props) => (!props.show ? "1px solid #fff" : "0")};
  border-radius: 3.9rem;
  color: ${(props) => (!props.show ? "#fff" : "#bcbcbc")};
  font-size: 3.8rem;
  cursor: pointer;
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5rem;
    width: 3.8rem;
    height: 3.8rem;
    background: ${(props) =>
      !props.show
        ? "url('/image/ico_login_arrow_off.png') no-repeat center/100%;"
        : "url('/image/ico_login_arrow_on.png') no-repeat center/100%;"};
  }
`;

const OptionListOn = styled.ul`
  display: ${(props) => (!props.show ? "none" : "block")};
  z-index: 2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 29.8rem;
  padding: 8rem 3.7rem 2.5rem;
  border: 1px solid #707070;
  border-radius: 4rem;
  background-color: #fff;
  font-size: 4.2rem;
  li {
    display: block;
    padding: 2rem 0;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    + li {
      border-top: 1px solid #bcbcbc;
    }
  }
`;
