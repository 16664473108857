import NoticePage from "../components/notice/NoticePage";




export default function Notice() {
    return (
        <>
            <NoticePage />
        </>
    )
}