import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { API, token } from "../../constants";
import moment from "moment";

export default function Timetable() {
  const [busTime, setBusTime] = useState([]);

  const today = moment().format("YYYY-MM-DD");

  console.log(today);

  const timeStamp = Math.floor(new Date().getTime() / 1000);

  const getTimeTable = async () => {
    try {
      const res = await axios.get(`${API}/dispatch/driver/${today}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setBusTime(res.data.object);
    } catch (err) {
      console.error(err);
    }
  };
  console.log(busTime);

  useEffect(() => {
    getTimeTable();
  }, []);

  return (
    <>
      <TableBody class="table-body" id="table-body">
        {busTime.map((data) => {
          console.log(data.unixEndTime);
          if (timeStamp >= data.unixEndTime) {
            return (
              <StatusEnd class="status status-end" id="end-status-round">
                <span id="end_1">{data.busRound + 1}회차</span>
                <span id="end_2">{data.startTime}</span>
                {/* <span id="end_3">{data.endTime}</span> */}
                <span id="end_4">
                  운행
                  <br />
                  완료
                </span>
              </StatusEnd>
            );
          } else if (timeStamp > data.unixStartTime) {
            return (
              <StatusIng class="status status-end" id="end-status-round">
                <span id="end_1">{data.busRound + 1}회차</span>
                <span id="end_2">{data.startTime}</span>
                {/* <span id="end_3">{data.endTime}</span> */}
                <span id="end_4">운행중</span>
              </StatusIng>
            );
          } else
            return (
              <StatusWait class="status status-wait" id="wait-status-round">
                <span id="end_1">{data.busRound + 1}회차</span>
                <span id="end_2">{data.startTime}</span>
                {/* <span id="end_3">{data.endTime}</span> */}
                <span id="wait_4">
                  운행
                  <br />
                  대기
                </span>
              </StatusWait>
            );
        })}

        {/* <div class="bus-none">
                      <span>배차일보가 없습니다</span>
                    </div> */}
      </TableBody>
    </>
  );
}

const TableBody = styled.div`
  height: calc(100% - 23rem);
  font-size: 3.7rem;
  font-weight: 700;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Status = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto 2.6rem;
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 2.8rem;
    box-sizing: border-box;
  }
  > span {
    width: 33.3%;
    text-align: center;
    line-height: 1.2;
  }
`;
const StatusEnd = styled(Status)`
  width: 64rem;
  height: 13.4rem;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  color: #bcbcbc;
  box-shadow: inset 2rem 2rem 3rem #e0e3ec;
  > span {
    width: 33.3%;
    text-align: center;
    line-height: 1.2;
  }
`;
const StatusIng = styled(Status)`
  width: 67.8rem;
  height: 13.4rem;
  padding: 0 1.9rem;
  border-radius: 7.2rem;
  background: linear-gradient(-45deg, #007473, #2a7da8);
  color: #f2f3f7;
  ::before {
    border: 0.3rem solid rgba(255, 255, 255, 0.5);
    border-radius: 7.2rem;
  }
`;
const StatusWait = styled(Status)`
  width: 65.4rem;
  height: 14.4rem;
  padding: 0 0.7rem;
  border-radius: 2.8rem;
  background: linear-gradient(-45deg, #e8ebf2, #f2f3f7);
  box-shadow: 2rem 2rem 3rem rgba(0, 0, 0, 0.1);
  ::before {
    border: 0.3rem solid rgba(255, 255, 255, 0.5);
  }
  > span:last-child {
    color: #007473;
  }
`;
const StatusMeal = styled(Status)`
  width: 64rem;
  height: 10.4rem;
  border-radius: 2.8rem;
  background: linear-gradient(-45deg, #e8ebf2, #f2f3f7);
  color: #bcbcbc;
  box-shadow: inset 2rem 2rem 3rem #e0e3ec;
  > span {
    width: 100%;
  }
`;
