import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components"
import { API, token } from "../../constants";

export default function UserInfo() {
  const today = moment().format("YYYY-MM-DD");
  const [busTime, setBusTime] = useState([]);
  const [driverInfo, setDriverInfo] = useState([]);
  const [driverNumber, setDriverNumber] = useState("");
  const getTimeTable = async () => {
    try {
      const res = await axios.get(`${API}/dispatch/driver/${today}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setBusTime(res.data.object);
    } catch (err) {
      console.error(err);
    }
  };
  const getDriver = async () => {
    const res = await axios.get(`${API}/route/driver/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "server-version": "2.0.2",
      },
    });
    setDriverInfo(res.data.object);
    setDriverNumber(res.data.object.busNumber.slice(-4));
    window.sessionStorage.setItem("routeId", res.data.object.routeId);
  };

  console.log(driverInfo);
  
  useEffect(() => {
    getDriver();
    getTimeTable();
  }, []);

    return(
        <UserBox>
              <Company>
                <CompanyTxt>
                  <span id="branchName">
                    {driverInfo.branchName}
                    {/* 영업소 이름 */}
                  </span>
                  <br />
                  영업소
                </CompanyTxt>
              </Company>
              <Bus>
                <BusTxt>
                  <span id="routeName">
                    {driverInfo.routeName}번 노선
                    {/* 노선이름 */}
                  </span>
                </BusTxt>
                <BusTxt1>
                  <span id="shift">
                    {driverInfo.shift}조
                  </span>
                  <strong>
                    <span id="driverName">
                    &nbsp;{driverInfo.driverName}
                    </span>
                  </strong>
                  님
                  <br />
                  <span id="busNumber">
                    {driverNumber} 차량
                  </span>
                </BusTxt1>
              </Bus>
              {busTime.length === 0 ? <DayOff></DayOff> : <Work></Work>}
            </UserBox>
    )
};

const UserBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32.6rem;
  padding: 0 0 0 9rem;
  /* .company::before {content:''; z-index:1; position:absolute; top:0; left:0; width:100%; height:100%; } */
`;
const Company = styled.div`
  position: relative;
  width: 21.2rem;
  height: 20.6rem;
  border: 0.3rem solid rgba(255, 255, 255, 0.4);
  background-color: #0b2a4a;
  border-radius: 3.8rem;
  text-align: center;
  line-height: 1.3;
  overflow: hidden;
  backdrop-filter: blur(2rem);
  -webkit-backdrop-filter: blur(2rem);
`;
const CompanyTxt = styled.p`
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: #fff;
  font-size: 5.2rem;
  font-weight: 700;
  text-align: center;
`;
const Bus = styled.div`
  padding-left: 4.5rem;
  color: #fff;
  line-height: 1.3;
`;
const BusTxt = styled.p`
  padding-bottom: 0.5rem;
  font-size: 5.2rem;
  font-weight: 700;
`;
const BusTxt1 = styled.p`
  font-size: 3.4rem;
`;
const DayOff = styled.div`
  position: absolute;
  bottom: 3rem;
  right: 4.8rem;
  width: 18.5rem;
  height: 5.4rem;
  background: url("/image/ico_day_off.png") no-repeat center;
  background-size: 100%;
`;
const Work = styled(DayOff)`
  background: url("/image/ico_day_on.png") no-repeat center;
  background-size: 100%;
`
