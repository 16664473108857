import styled from "styled-components";
import { Link } from 'react-router-dom';

export default function MainHeader() {
  return (
    <>
      <header>
        <Link to = "/home">
          <Logo class="logo">
              <img src="/image/logo_header.png" alt="kikiB" />
          </Logo>
        </Link>
        <PageTitle class="page-title">메인화면</PageTitle>
        <BtnBox class="btn-box">
          <a href="#">
            <img src="/image/ico_msg.png" alt="메시지" />
          </a>
          <a href="#">
            <img src="/image/ico_common_alarm.png" alt="알림" />
            <AlarmTag>!</AlarmTag>
          </a>
        </BtnBox>
      </header>
    </>
  );
}

const Logo = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4.8rem;
  width: 10rem;
`;
const PageTitle = styled.p`
  font-size: 3.4rem;
  font-weight: 700;
  text-align: center;
  line-height: 10rem;
`;
const BtnBox = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6rem;
  a {
    position: relative;
    display: inline-block;
    width: 4rem;
    + a {
        margin-left: 4rem; 
    }
  }
`;
const AlarmTag = styled.span`
  position: absolute;
  top: -1rem;
  right: -1.4rem;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  background-color: #007473;
  color: #fff;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
`
