import styled from "styled-components";
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, getDate, sub, add, getMonth, addMonths } from "date-fns";
import { isSameMonth, isSameDay, addDays, format, subDays } from "date-fns";
import WorkDays from "./WorkDays";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API, token } from "../../constants";
import { useEffect, useState } from "react";

export default function RenderCells({
  currentMonth,
  selectedDate,
  nextMonth,
  prevMonth,
}) {
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = subDays(startOfWeek(monthStart), -1);
  let endDate = endOfWeek(monthEnd);
  const yearMonth = format(currentMonth, "yyyy-MM");
  const month = addMonths(currentMonth,1);
  const linkMonth = format(month,"yyyy-MM");

  console.log(month);
  console.log(yearMonth);
  const [workDay, setWorkDay] = useState([
    {
      data: "",
      id: "",
      status: ""
    },
  ]);
  const getSchedule = async () => {
    const res = await axios.get(`${API}/driver/work?yearMonth=${yearMonth}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(res);
    setWorkDay(res.data.object);
  };
  useEffect(() => {
    getSchedule();
  },[prevMonth]);
  console.log(workDay);

  const isWork = (v) => {
    if(v.status.includes("WORK")) {
      return true;
    }
  }

  const work = workDay.filter(isWork);
  console.log(work);
console.log(currentMonth);
  // 날짜 클릭 attribute 지정 session Stotage//
  // 날짜를 뽑고
  const navigate = useNavigate();
  const handleClickDate = (e) => {
    const selectDate = e.target.innerText;
    navigate("/schedule_detail", {
      state: {
        date: selectDate,
        month: yearMonth,
        workDay: workDay,
        work: work,
        linkMonth: linkMonth,
      },
    });
    // console.log(selectDate);
  };
  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = "";

  const dateArr = [];
  if (workDay.length > 1) {
    workDay.map((v) => {
      let dateDay = v.date.slice(-2);
      if (dateDay < 10) {
        dateDay = dateDay.slice(-1);
      }
      dateArr.push({
        date: dateDay,
        status: v.status,
      });
    });
  }
  // console.log(dateArr);

  let workCount = 0;
  let leaveCount = 0;
  let annualCount = 0;
  let status = "";
  let nameClass = "";

  workDay.forEach((v) => {
    if (v.status.includes("WORK")) {
      workCount = workCount + 1;
    }
    if (v.status.includes("LEAVE")) {
      leaveCount = leaveCount + 1;
    }
    if (v.status.includes("ANNUAL")) {
      annualCount = annualCount + 1;
    }
  });
// 월요일이 2일인 경우 row 한줄을 추가해라.

// console.log(day);
// console.log(subDays(startOfWeek(monthEnd),-1));
const weekStartEndDate = subDays(startOfWeek(monthEnd),-1);
// console.log(weekStartEndDate);
// console.log(add(weekStartEndDate,{days: 7}));
// console.log(getMonth(currentMonth));
// console.log(getMonth(add(weekStartEndDate,{days: 7})));
// console.log(getMonth(weekStartEndDate));
let endDay = endDate;


if(getDate(startDate) === 2) {
  day = sub(startDate, {days: 7});
} else {
  day = startDate;
}
if(getMonth(currentMonth) === getMonth(weekStartEndDate)) {
 endDay = add(endDate, {days:2})
//  console.log(endDay);
} else {
  endDay = endDate;
}

while (day <= endDay) {
    // console.log(day);
    // console.log(endDate);
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, "d");
      for (let j = 0; j < dateArr.length; j++) {
        // console.log(j);
        status = "";
        nameClass = "";
        if (formattedDate === dateArr[j].date) {
          status = dateArr[j].status;
          if (status.includes("WORK") || status === "LEAVE_EARLY") {
            nameClass = "day-work";
          } else if (status.includes("LEAVE")) {
            nameClass = "day-off";
          } else {
            nameClass = "type-leave";
          }
          break;
        } else {
          nameClass = "";
          status = "";
        }
        // console.log(status ,nameClass, formattedDate);
      }
      days.push(
        <div key={day}>
          <span
            onClick={handleClickDate}
            className={`${
              !isSameMonth(day, monthStart)
                ? "day-num"
                : isSameDay(day, selectedDate)
                ? "day-today"
                : format(currentMonth, "M") !== format(day, "M")
                ? "not-valid"
                : nameClass
            }`}
          >
            {formattedDate}
          </span>
        </div>
      );
      // console.log(nameClass);
      day = addDays(day, 1);
    }
    rows.push(<TableRow key={day}>{days}</TableRow>);
    // console.log(days);

    days = [];
    if (rows.length > 6) {
      rows.pop();
    }
  }
  // console.log(rows);
  // console.log(day);
// row 가 6줄이 아니면 1줄을 추가해라. 

  return (
    <TableBody>
      <div>{rows}</div>
      <WorkDays
        workCount={workCount}
        leaveCount={leaveCount}
        annualCount={annualCount}
      />
    </TableBody>
  );
}

const TableBody = styled.div`
  height: calc(100% - 9rem);
  border-radius: 1.2rem 1.2rem 0.8rem 0.8rem;
  background-color: #f2f3f7;
  box-shadow: 0 -1rem 1.2rem rgba(0, 0, 0, 0.02);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const TableRow = styled.div`
  height: 16rem;
  background: url("../image/bg_calendar_week.png") repeat-y top 1rem center;
  background-size: 73rem 16rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2.5rem 2.5rem 1rem;
  div {
    width: 3rem;
    span {
      position: relative;
      padding: 1.3rem 0 1.8rem;
      color: #bcbcbc;
      font-size: 3.8rem;
      text-align: center;
    }
    .day-num {
      font-size: 3rem;
      font-weight: 400;
    }
    .day-work {
      color: #000;
    }
    .day-today {
      color: #007473;
      ::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 4.4rem;
        height: 0.6rem;
        border-radius: 0.6rem;
        background-color: #007473;
      }
    }
    .day-off {
      color: #e12c03;
      .type-leave {
        ::after {
          content: "연차";
          position: absolute;
          bottom: -1.5rem;
          left: 50%;
          transform: translateX(-50%);
          color: #e12c03;
          font-size: 2.6rem;
          white-space: nowrap;
        }
      }
    }
  }
`;
