import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { API, routeId, token } from "../../constants";
import NoticeHeader from "./NoticeHeader";
import NoticeNavBar from "./NoticeNavigation";

export default function NoticePage() {
  const [status, setStatus] = useState("전체");
  const [allNoticeList, setAllNoticeList] = useState();
  const [noticeList, setNoticeList] = useState();
  const handleClick = (e) => {
    setStatus(e.target.name);
  };
  const getAllNotice = async () => {
    const res = await axios.get(
      `${API}/notice?filtered=false&routeId=${routeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res);
    setAllNoticeList(res.data.object);
  };
  const getNotice = async () => {
    const res = await axios.get(
      `${API}/notice?filtered=true&routeId=${routeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res);
    setNoticeList(res.data.object);
  };
  useEffect(() => {
    getAllNotice();
    getNotice();
  }, []);
  return (
    <>
      <div id="wrap">
        <Container class="container notice">
          <NoticeHeader />

          <Inner class="inner">
            <TabList class="tab-list">
              <BtnTab
                onClick={handleClick}
                name="전체"
                className={status === "전체" ? "on" : ""}
              >
                전체
              </BtnTab>
              <BtnTab
                onClick={handleClick}
                name="영업소"
                className={status === "영업소" ? "on" : ""}
              >
                영업소
              </BtnTab>
            </TabList>

            <CurrentContent>
              {status === "전체" ? (
                <Content>
                  <ul className="notice-list">
                    {allNoticeList?.map((data) => {
                      const updateDate =
                        data.createdAt.slice(5, 7) +
                        "월" +
                        data.createdAt.slice(8, 10) +
                        "일";
                      // console.log(updateDate);
                      return (
                        <Link to={`/notice/${data.id}`} state={{ id: data.id }}>
                          <li className="notice-item type-txt">
                            <span>
                              <p className={data.read === true ? "read" : "title"}>{data.title}</p>
                              <ul className="info">
                                <li>{data.writerName}</li>
                                <li>{updateDate}</li>
                                <li>조회 {data.hit}</li>
                              </ul>
                            </span>
                          </li>
                        </Link>
                      );
                    })}
                    ;
                  </ul>
                </Content>
              ) : (
                <Content>
                  <ul className="notice-list">
                    {noticeList?.map((data) => {
                      const updateDate =
                        data.updatedAt.slice(5, 7) +
                        "월" +
                        data.updatedAt.slice(8, 10) +
                        "일";
                      // console.log(updateDate);
                      return (
                        <li className="notice-item type-txt">
                          <span>
                            <p className="title">{data.title}</p>
                            <ul className="info">
                              <li>{data.writerName}</li>
                              <li>{updateDate}</li>
                              <li>조회 0</li>
                            </ul>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </Content>
              )}
            </CurrentContent>
          </Inner>
          <NoticeNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
`;
const Inner = styled.div`
  height: calc(100% - 10rem);
  background: url(/image/bg_notice.jpg) no-repeat top center/contain;
`;
const TabList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 4.8rem;
  .on {
    border-color: #f2f3f7;
    background-color: #007473;
    color: #fff;
    box-shadow: none;
  }
`;
const BtnTab = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31.4rem;
  height: 9rem;
  border: 0.3rem solid #767676;
  border-radius: 6.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(1.8rem);
  -webkit-backdrop-filter: blur(1.8rem);
  color: rgba(188, 188, 188, 0.5);
  font-size: 4.2rem;
  font-weight: 700;
  box-shadow: inset 1.4rem 1.4rem 3rem rgba(0, 0, 0, 0.34);
`;
const CurrentContent = styled.div`
  height: calc(100% - 14.5rem);
  padding: 4rem 0 0;
  border-top-right-radius: 1.2rem;
  border-top-left-radius: 1.2rem;
  background-color: #e8ebf2;
  overflow: hidden;
`;
const Content = styled.div`
  /* display: none; */
  height: 100%;
  padding: 0 5rem;
  background-color: #f2f3f7;
  padding-bottom: 17.4rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .notice-item + .notice-item {
    border-top: 1px solid rgba(188, 188, 188, 0.3);
  }
  .notice-item {
    span {
      display: block;
      padding: 5rem 0;
    }
    .title {
      margin-bottom: 2.5rem;
      font-size: 3.8rem;
      font-weight: 700;
      line-height: 1.2;
      word-break: keep-all;
      padding-right: 2.5rem;
    }
    .read {
      margin-bottom: 2.5rem;
      font-size: 3.8rem;
      font-weight: 700;
      line-height: 1.2;
      word-break: keep-all;
      padding-right: 2.5rem;
      color: #bcbcbc;
    }
    .info {
      display: flex;
      color: #767676;
      font-size: 2.8rem;
      li + li {
        margin-left: 1.5rem;
        padding-left: 1.5rem;
        border-left: 0.2rem solid #767676;
      }
    }
  }
  .type-img {
    a {
      display: flex;
    }
    .img-box {
      width: 17.4rem;
      img {
        border-radius: 1.8rem;
      }
    }
    .txt-box {
      align-self: center;
      width: 41.5rem;
      margin-left: 3.5rem;
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;
