import NoticeDetailPage from "../components/noticeDetail/NoticeDetailPage";




export default function NoticeDetail() {
    return (
        <>
            <NoticeDetailPage />
        </>
    )
}