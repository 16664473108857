import styled from "styled-components";
import { Link } from "react-router-dom";
import ScheduleHeader from "./ScheduleHeader";
import ManageNavBar from "../manage/ManageNavigation";
import Calendar from "./Calendar";

export default function SchedulePage() {
  return (
    <>
      <div id="wrap" class="scroll-hidden">
        <Container class="container schedule">
          <ScheduleHeader />
          <Calendar />
          <ManageNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;

