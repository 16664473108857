import axios from "axios";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API, token } from "../../constants";
import ManageNavBar from "../manage/ManageNavigation";
import ScheduleHeader from "../schedule/ScheduleHeader";
import DailyWorker from "./DailyWorker";
import RequestExchange from "./RequestExchange";
import RequestLeave from "./RequestLeave";
import WorkList from "./WorkList";

export default function ScheduleDetailPage() {
  const [worker, setWorker] = useState([]);
  const [dayOff, setDayOff] = useState([]);
  const [driving, setDriving] = useState();
  const routeId = sessionStorage.getItem("routeId");
  const location = useLocation();
  const date = location.state.date;
  const month = location.state.month;
  const workDay = location.state.workDay;
  const work = location.state.work;
  const linkMonth = location.state.linkMonth;
  console.log(work);
  console.log(date);
  console.log(month);
  console.log(workDay);
  const confirmDate = date < 10 ? "0" + date : date;
  const linkDate = month + "-" + confirmDate;
  console.log(linkDate);

  let annualId = "";
  for (let i = 0; i < workDay.length; i++) {
    if (linkDate === workDay[i].date) {
      annualId = workDay[i].id;
    }
  }
  console.log(annualId);

  const getWorker = async () => {
    const res = await axios.get(`${API}/work/${routeId}/${linkDate}/work`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setWorker(res.data.object);
  };
  const getDayOff = async () => {
    const res = await axios.get(`${API}/work/${routeId}/${linkDate}/not-work`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setDayOff(res.data.object);
  };
  const getDriving = async () => {
    const res = await axios.get(`${API}/route/${routeId}/${linkDate}/unit`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setDriving(res.data.object);
  };

  // console.log(worker);
  // console.log(dayOff);
  // console.log(driving);
  useEffect(() => {
    getWorker();
    getDayOff();
    getDriving();
  }, []);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showExchangeModal, setShowExchangeModal] = useState(false);
  const openLeaveModal = () => {
    setShowLeaveModal(true);
  };
  const closeLeaveModal = () => {
    setShowLeaveModal(false);
  };
  const openExchangeModal = () => {
    setShowExchangeModal(true);
  };
  const closeExchangeModal = () => {
    setShowExchangeModal(false);
  };
  const getExchange = async () => {
    try {
      const res = await axios.get(`${API}/work/${routeId}/${linkDate}/not-work`, {
        header: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getExchange();
  }, []);
  return (
    <>
      <div id="wrap">
        <Container>
          <ScheduleHeader />
          {showLeaveModal ? (
            <RequestLeave
              closeLeaveModal={closeLeaveModal}
              month={month}
              linkDate={linkDate}
              annualId={annualId}
            />
          ) : null}
          {showExchangeModal ? (
            <RequestExchange
              closeExchangeModal={closeExchangeModal}
              month={month}
              linkDate={linkDate}
              annualId={annualId}
            />
          ) : null}
          <Inner class="inner">
            <DailyWorker worker={worker} dayOff={dayOff} driving={driving} />
            <WorkList
              worker={worker}
              dayOff={dayOff}
              linkDate={linkDate}
              annualId={annualId}
              openLeaveModal={openLeaveModal}
              openExchangeModal={openExchangeModal}
              work={work}
              linkMonth={linkMonth}
            />
          </Inner>
        </Container>
        <ManageNavBar />
      </div>
    </>
  );
}

const Container = styled.div`
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 17.5rem;
  background-color: #f2f3f7;
  overflow-y: hidden;
`;
const Inner = styled.div`
  height: calc(100% - 10rem);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  background-image: url("/image/bg_worker_jan.jpg");
`;
