import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { API, name, routeId, token } from "../../constants";
import SelectDate from "./SelectDate";
import SelectDriver from "./SelectDriver";

export default function RequestExchange({
  closeExchangeModal,
  month,
  linkDate,
  annualId,
}) {
  const [selectDriver, setSelectDriver] = useState("승무원");
  const [selectDate, setSelectDate] = useState("날짜");
  const [leaveDriver, setLeaveDriver] = useState();
  const [myLeaveDate, setMyLeaveDate] = useState();
  const [myLeaveId, setMyLeaveId] = useState("");
  const [yourLeaveId, setYourLeaveId] = useState("");
  console.log(myLeaveId);
  console.log(annualId);
  console.log(yourLeaveId);
  const today = linkDate.slice(5, 7) + "월 " + linkDate.slice(8, 10) + "일";
  const getLeaveDriver = async () => {
    try {
      const res = await axios.get(
        `${API}/work/${routeId}/${linkDate}/not-work`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res);
      setLeaveDriver(res.data.object);
    } catch (err) {
      console.log(err);
    }
  };
  const getMyLeaveDate = async () => {
    try {
      const res = await axios.get(`${API}/driver/leave?yearMonth=${month}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
      setMyLeaveDate(res.data.object);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getLeaveDriver();
    getMyLeaveDate();
  }, []);
 
  const OnExchange = async (data) => {
    data.preventDefault();
    const userData = {
      reqDriverLeaveId: myLeaveId,
      reqDriverWorkId: annualId ,
      resDriverLeaveId: yourLeaveId,
    };
    try {
      const res = await axios.post(`${API}/replace/request`, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("휴무교환 성공");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <AlertExchange>
        <TitleBox>
          <div className="title">
            휴무 교환 신청일
            <br />
            <span>{today}</span>
          </div>
        </TitleBox>

        <ContentBox>
          <NotifyBtn>
            <img src="/image/ico_notify.png" alt="" />
          </NotifyBtn>

          <p className="title">휴무 교환 대상</p>
          <SelectDriver
            leaveDriver={leaveDriver}
            setSelectDriver={setSelectDriver}
            setYourLeaveId={setYourLeaveId}
          />
          <p className="title">교환할 내 휴무일</p>
          <SelectDate myLeaveDate={myLeaveDate} setSelectDate={setSelectDate} setMyLeaveId={setMyLeaveId} />
        </ContentBox>

        <IcoArrow class="ico_arrow">
          <img src="/image/ico_arrow_content.png" alt="" />
        </IcoArrow>

        <Box02 class="content-box box02">
          {/* <!-- 선택 완료 시 .selected 추가 (버튼, 텍스트 색상 활성화) --> */}
          <ExchangeBox class="exchange-box">
            <Item class="item item01">
              <strong>{name}</strong>
              <SelectCont className={selectDate === "날짜" ? "off" : ""}>
                {selectDate}
              </SelectCont>
            </Item>

            <Item02 class="item item02">
              <SelectCont className={selectDriver === "승무원" ? "off" : ""}>
                {selectDriver}
              </SelectCont>
              <strong>{today}</strong>
            </Item02>
          </ExchangeBox>

          <NotifyTxt class="notify-txt">
            {selectDate !== "날짜" && selectDriver !== "승무원"
              ? "휴무 교환 정보가 맞습니까?"
              : "정보를 선택해주세요"}
          </NotifyTxt>
          {/* <!-- 선택 완료 시 텍스트 변경 : 휴무 교환 정보가 맞습니까? --> */}

          <BtnBox class="btn-box">
            <CancelBtn onClick={closeExchangeModal}>취소</CancelBtn>
            <ConfirmBtn onClick={OnExchange} class="btn-popup btn-confirm">확인</ConfirmBtn>
          </BtnBox>
        </Box02>
      </AlertExchange>
    </>
  );
}

const AlertExchange = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 4rem 0 25.4rem;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(3rem);
  -webkit-backdrop-filter: blur(3rem);
  text-align: center;
  overflow-y: auto;
  z-index: 200;
`;
const TitleBox = styled.div`
  color: #fff;
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 64.2rem;
    height: 16rem;
    margin: 0 auto;
    border-radius: 8rem;
    background: rgba(0, 0, 0, 0.4);
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 1.3;
    span {
      font-size: 4.8rem;
    }
  }
  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65.4rem;
    height: 10.8rem;
    margin: 5.4rem auto 0;
    border-radius: 5.4rem;
    background: rgba(255, 255, 255, 0.2);
    font-size: 4.2rem;
  }
`;
const ContentBox = styled.div`
  position: relative;
  width: 56.6rem;
  margin: 3rem auto 0;
  padding: 5rem 5.8rem 7rem;
  border-radius: 3rem;
  background-color: #fff;
  line-height: 1.3;
  .title {
    padding-bottom: 3rem;
    font-size: 4.2rem;
    font-weight: 700;
  }
`;
const NotifyBtn = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 5.4rem;
`;
const SelectBox = styled.div`
  position: relative;
  width: 45.2rem;
  + .title {
    padding-top: 4rem;
  }
`;
const ToggleBtn = styled.span`
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  width: 4.8rem;
  height: 4.8rem;
`;
const SelectBtn = styled.button`
  z-index: 1;
  position: relative;
  display: block;
  width: 100%;
  height: 8.8rem;
  line-height: 8.8rem;
  margin: 0 auto;
  border: 1px solid #707070;
  border-radius: 2rem;
  font-size: 3.8rem;
  box-shadow: inset 0.2rem 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
`;
const OptionList = styled.ul`
  display: none;
  font-size: 3.8rem;
  .on {
    display: block;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 9.6rem;
    border: 1px solid #707070;
    border-radius: 2rem;
    background-color: #fff;
    font-size: 4.2rem;
  }
  li {
    a {
      display: block;
      padding: 0.8rem 0;
    }
  }
  li + li {
    border-top: 1px solid #bcbcbc;
  }
`;
const IcoArrow = styled.span`
  display: block;
  width: 8.8rem;
  height: 8rem;
  margin: 0 auto;
  background: url("/image/ico_arrow_content.png") no-repeat center/100%;
`;
const Box02 = styled.div`
  position: relative;
  margin: 3rem auto 0;
  padding: 5rem 5.8rem 7rem;
  border-radius: 3rem;
  background-color: #fff;
  line-height: 1.3;
  width: 61.8rem;
  margin-top: 0;
  padding: 5rem 6.8rem 7rem;
`;
const ExchangeBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20rem;
  height: 18rem;
  border-radius: 2.2rem;
  background-color: #f2f3f7;
  font-size: 3.8rem;
  line-height: 1.5;
  box-shadow: inset 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
  .off {
    color: #bcbcbc;
  }
`;
const SelectCont = styled.strong`
  color: #007473;
`;
const Item02 = styled(Item)`
  ::after {
    content: "";
    position: absolute;
    transform: translateY(0%);
    left: 27.5rem;
    width: 6.8rem;
    height: 6.8rem;
    background: url("/image/ico_exchange.png") no-repeat center/100%;
  }
`;
const NotifyTxt = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13rem;
  color: #e12c03;
  font-size: 3.4rem;
  font-weight: 700;
`;
const BtnBox = styled.div`
  display: flex;
  justify-content: center;
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 19.4rem;
    height: 8rem;
    margin: 0 2.5rem;
    border-radius: 9.7rem;
    font-size: 4.2rem;
    font-weight: 700;
  }
`;
const CancelBtn = styled.button`
  border: 0.3rem solid #bcbcbc;
  color: #767676;
  box-shadow: inset 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
`;
const ConfirmBtn = styled.button`
  background-color: #bcbcbc;
  color: #767676;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
`;

//휴무교환신청
