import styled from "styled-components";
import { useState } from "react";
import RenderHeader from "./CalendarHeader";
import { addMonths, subMonths } from "date-fns";
import RenderCells from "./CalendarBody";

export default function Calendar() {

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  // console.log(workDay);

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  return (
    <CalendarWrap class="calendar-wrap month-01">
      <RenderHeader
        currentMonth={currentMonth}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
      />
      <CalendarCont class="calendar">
        <TableHead class="table-head table-row">
          <span>월</span>
          <span>화</span>
          <span>수</span>
          <span>목</span>
          <span>금</span>
          <span className="saturday">토</span>
          <span className="sunday">일</span>
        </TableHead>
        <RenderCells
          currentMonth={currentMonth}
          selectedDate={selectedDate}
          nextMonth={nextMonth}
          prevMonth={prevMonth}
        />
      </CalendarCont>
    </CalendarWrap>
  );
}

const CalendarWrap = styled.div`
  height: calc(100% - 10rem);
  background-image: url(/image/bg_calendar_jan.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  background-color: #f2f3f7;
`;

const CalendarCont = styled.div`
  z-index: 101;
  position: relative;
  height: calc(100% - 13.6rem);
  background-color: #f2f3f7;
  font-weight: 700;
`;
const TableHead = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2.5rem 2.5rem 1rem;
  height: 7.5rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  background-color: #fff;
  span {
    font-size: 3.4rem;
  }
  .sunday {
    color: #e12c03;
  }
  .saturday {
    color: #0025a0;
  }
`;
