import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export default function TimetableRoundContent() {
  const location = useLocation();
  console.log(location);
  const round = location.state.round;
  const roundNum = round;
  const roundArr = location.state.roundArr;
  console.log(round);
  console.log(roundArr);

  return (
    <CurrentContent>
      <Content>
        <TimeTableBox>
          <TableHead>
            <span>No.</span>
            <span>차량</span>
            <span>성명</span>
            <span>출발</span>
          </TableHead>
          <TableBody>
            {roundArr[roundNum].map((v, i) => {
              return (
            <ListRowOn>
              <span>{i+1}.</span>
              <span>{v.busNumber}</span>
              <span>{v.driverName}</span>
              <span>{v.startTime}</span>
            </ListRowOn>
              )
            })}
          </TableBody>
        </TimeTableBox>
      </Content>
    </CurrentContent>
  );
}

const CurrentContent = styled.div`
  height: calc(100% - 14.5rem);
  padding: 2rem 3.6rem 0;
  border-top-right-radius: 6rem;
  border-top-left-radius: 6rem;
  background-color: #f2f3f7;
  overflow: hidden;
`;
const Content = styled.div`
  display: block;
  height: 100%;
`;
const TimeTableBox = styled.div`
  height: 100%;
`;
const TableHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2.2rem;
  padding: 0 1.9rem;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 65.4rem;
    height: 0.4rem;
    background-color: #007473;
  }
  > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8.6rem;
    font-size: 3.8rem;
    font-weight: 700;
  }
`;

const TableBody = styled.div`
  height: 100%;
  padding-bottom: 42rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .me {
    border-color: #007473;
  }
`;
const ListRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 66rem;
  height: 13.4rem;
  margin: 0 auto 2.2rem;
  border: 0.4rem solid #fff;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  font-size: 3.4rem;
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  span {
    width: 33%;
    text-align: center;
    line-height: 1.2;
    :first-child {
      font-weight: 700;
    }
    :nth-child(2) {
      width: 33%;
    }
    :nth-child(3) {
      width: 33%;
      font-weight: 700;
      /* font-size: 2.8rem; */
    }
  }
`;
const ListRowOn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 66rem;
  height: 13.4rem;
  margin: 0 auto 2.2rem;
  border: 0.4rem solid #fff;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  font-size: 3.4rem;
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  span {
    width: 50%;
    text-align: center;
    line-height: 1.2;
    :first-child {
      font-weight: 700;
    }
    :nth-child(2) {
      width: 50%;
    }
  }
`;
