import axios from "axios";
import styled from "styled-components";
import { API, token } from "../../constants";

export default function AgreementModal({
  replaceId,
  handleOffPopup,
  setDeletePopup,
}) {
  console.log(replaceId);
  const putAgree = async () => {
    try {
      const res = await axios.put(`${API}/replace/${replaceId}/request`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setDeletePopup(false);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const putDeny = async () => {
    try {
      const res = await axios.put(`${API}/replace/${replaceId}/deny`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setDeletePopup(false);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <DeleteAnnualDim>
        <DeleteAnnual>
          <MainMsg>
            선택하신 <strong>교환 요청</strong>을<br />
            <strong>합의 / 거절</strong>하시겠습니까?
          </MainMsg>

          <BtnBox>
            <BtnCancel onClick={putDeny}>거절</BtnCancel>
            <BtnConfirm onClick={putAgree}>합의</BtnConfirm>
          </BtnBox>
        </DeleteAnnual>
      </DeleteAnnualDim>
    </>
  );
}
const DeleteAnnualDim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
`;
const DeleteAnnual = styled.div`
  z-index: 163;
  position: fixed;
  bottom: 23rem;
  left: 50%;
  transform: translateX(-50%);
  width: 59.4rem;
  padding: 9rem 0 6rem;
  border-radius: 3rem;
  background-color: #fff;
  text-align: center;
  box-shadow: 0.8rem 0.8rem 2.4rem rgb(0 0 0 / 20%);
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 17.4rem;
    height: 2.2rem;
    background: url(/image/alert_tag.png) no-repeat center/100%;
  }
  .point {
    color: #e12c03;
    margin-top: 4.5rem;
    font-size: 3.4rem;
  }
`;
const MainMsg = styled.p`
  font-size: 3.8rem;
  line-height: 1.3;
`;
const BtnBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 19.4rem;
    height: 8rem;
    margin: 0 2.5rem;
    border-radius: 9.7rem;
    font-size: 4.2rem;
    font-weight: 700;
  }
`;
const BtnCancel = styled.button`
  border: 0.3rem solid #bcbcbc;
  color: #767676;
  box-shadow: inset 0 0.3rem 0.6rem rgb(0 0 0 / 16%);
`;
const BtnConfirm = styled.button`
  background: linear-gradient(0.25turn, #2a7da8, #28af86, #007473);
  color: #fff;
  box-shadow: 0 0.3rem 0.6rem rgb(0 0 0 / 16%);
`;
