import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function TimetableDetailContent({ status, timetable }) {
  const name = sessionStorage.getItem("name");
  const timetableArr = [];
  timetable.map((v) => {
    timetableArr.push(v.driverName);
  });
  const sum = timetableArr.reduce((p, c) => {
    p[c] = (p[c] || 0) + 1;
    return p;
  }, {});
  console.log(sum);
  const raceCount = Object.keys(sum).map((v) => {
    return { name: v, count: sum[v] };
  });

  const roundGroup = timetable.reduce((a, b) => {
    if (Object.keys(a).find((key) => key == b["busRound"])) {
      a[b["busRound"]].push(b);
    } else {
      a[b["busRound"]] = [b];
    }
    return a;
  }, []);
  console.log(roundGroup);
  const roundArr = [];
  let count = 0;


  for (let i = 0; i < roundGroup.length; i++) {
      roundGroup[i].map((v) => {
       if (name === v.driverName) {
          roundArr.push(v.startTime);
          count ++;
        }
      });
    }
    
    if (roundArr.length < roundGroup.length){
    roundArr.push("-")
  }

    if (count === 0) {
    for (let j = 0; j < roundGroup.length - 1; j++) {
      roundArr.push("-");
    }
  }

  console.log(raceCount);
  console.log(roundArr);

  const navigate = useNavigate();
  const handleClickRound = (e) => {;
const selectRound = e.target.id;
    navigate("/timetableRound", {
      state: {
        round: selectRound,
        roundArr: roundGroup,
      },
    });
  };

  return (
    <CurrentContent>
      <Content>
        {status === "요약" ? (
          <>
            <TimeTableBox>
              <TableHead>
                <span>순번</span>
                <span>성명</span>
                <span>운행횟수</span>
              </TableHead>
              <TableBody>
                {raceCount.map((data, i) => {
                  return (
                    <>
                      <ListRow className={data.name === name ? "me" : ""}>
                        <span>{i + 1}.</span>
                        <span>{data.name}</span>
                        <span>{data.count}</span>
                      </ListRow>
                    </>
                  );
                })}
              </TableBody>
            </TimeTableBox>
          </>
        ) : (
          <>
            <TimeTableBox>
              <TableHead>
                <span>회차</span>
                <span>운행시간</span>
              </TableHead>
              <TableBody>
                {roundArr.map((v, i) => {
                  return (
                    <>
                      <ListRowOn onClick={handleClickRound} id={i}>
                          <span id={i}>{i + 1}회차</span>
                          <span id={i}>{v}</span>
                      </ListRowOn>
                    </>
                  );
                })}
              </TableBody>
            </TimeTableBox>
          </>
        )}
      </Content>
    </CurrentContent>
  );
}

const CurrentContent = styled.div`
  height: calc(100% - 14.5rem);
  padding: 2rem 3.6rem 0;
  border-top-right-radius: 6rem;
  border-top-left-radius: 6rem;
  background-color: #f2f3f7;
  overflow: hidden;
`;
const Content = styled.div`
  display: block;
  height: 100%;
`;
const TimeTableBox = styled.div`
  height: 100%;
`;
const TableHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2.2rem;
  padding: 0 1.9rem;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 65.4rem;
    height: 0.4rem;
    background-color: #007473;
  }
  > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8.6rem;
    font-size: 3.8rem;
    font-weight: 700;
  }
`;

const TableBody = styled.div`
  height: 100%;
  padding-bottom: 42rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .me {
    border-color: #007473;
  }
`;
const ListRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 66rem;
  height: 13.4rem;
  margin: 0 auto 2.2rem;
  border: 0.4rem solid #fff;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  font-size: 3.4rem;
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  span {
    width: 33%;
    text-align: center;
    line-height: 1.2;
    :first-child {
      font-weight: 700;
    }
    :nth-child(2) {
      width: 33%;
    }
    :nth-child(3) {
      width: 33%;
      font-weight: 700;
      /* font-size: 2.8rem; */
    }
  }
`;
const ListRowOn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 66rem;
  height: 13.4rem;
  margin: 0 auto 2.2rem;
  border: 0.4rem solid #fff;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  font-size: 3.4rem;
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  span {
    width: 50%;
    text-align: center;
    line-height: 1.2;
    :first-child {
      font-weight: 700;
    }
    :nth-child(2) {
      color: #767676;
      width: 50%;
      /* z-index: -1; */
    }
  }
`;
