import ScheduleDetailPage from "../components/scheduleDetail/ScheduleDetailPage";




export default function ScheduleDetail() {
    return (
        <>
            <ScheduleDetailPage />
        </>
    )
}