import { Route, Routes } from "react-router-dom";
import "./style/global.css"
import Login from "./pages/Login";
import Home from "./pages/Home";
import Manage from "./pages/Manage";
import Schedule from "./pages/Schedule";
import ScheduleDetail from "./pages/ScheduleDetail";
import TimetableCheck from "./pages/TimetableCheck";
import Timetable from "./pages/Timetable";
import TimetableDetail from "./pages/TimetableDetail";
import TimetableRound from "./pages/TimetableRound";
import Condition from "./pages/Condition";
import ConditionAnnual from "./pages/ConditionAnnual";
import ConditionRequest from "./pages/ConditionRequest";
import ConditionExchange from "./pages/ConditionExchange";
import Notice from "./pages/Notice";
import NoticeDetail from "./pages/NoticeDetail";
import ConditionAgreement from "./pages/ConditionAgreement";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/timetable" element={<Timetable />} />
        <Route path="/timetable_check" element={<TimetableCheck/>} />
        <Route path="/timetable_detail" element={<TimetableDetail/>} />
        <Route path="/timetable_round" element={<TimetableRound/>} />
        <Route path="/manage" element={<Manage />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/schedule_detail" element={<ScheduleDetail />} />
        <Route path="/condition" element={<Condition />} />
        <Route path="/condition_annual" element={<ConditionAnnual />} />
        <Route path="/condition_request" element={<ConditionRequest />} />
        <Route path="/condition_exchange" element={<ConditionExchange />} />
        <Route path="/condition_agreement" element={<ConditionAgreement />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/notice/:id" element={<NoticeDetail />} />
      </Routes>
    </>
  );
}

export default App;