import axios from "axios";
import styled from "styled-components";

export default function LoginModal() {
  return (
    <>
      <ModalBox>
        <CloseBtn>
          <img src="/image/alert_tag.png" alt="닫기 버튼"></img>
        </CloseBtn>

        <ModalContent>
          <img src="/image/img_login_inquiry.png" alt="안내문"></img>
        </ModalContent>
      </ModalBox>
    </>
  );
}

const ModalBox = styled.div`
  display: none;
  z-index: 150;
  position: fixed;
  top: auto;
  bottom: 0;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 750px;
  padding: 9rem 5.5rem;
  border-radius: 7rem 7rem 0 0;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(5rem);
  -webkit-backdrop-filter: blur(5rem);
`
const CloseBtn = styled.a`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 17.4rem;
  height: 2.2rem;
`
const ModalContent = styled.div`
  position: relative;
  width: 64.1rem;
  border-radius: 7.9rem;
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
`
