
import styled from "styled-components";

export default function DailyWorker({ worker, dayOff, driving}) {
  
  return (
    <WorkerContainer class="daily-worker">
      <WorkerNum class="worker-num">
        <li>
          <span>가동대수</span>
          <br />
          <strong>{driving}대</strong>
        </li>
        <li>
          <span>근무인원</span>
          <br />
          <strong>{worker.length}명</strong>
        </li>
        <li>
          <span>휴무인원</span>
          <br />
          <strong>{dayOff.length}명</strong>
        </li>
      </WorkerNum>
    </WorkerContainer>
  );
}

const WorkerContainer = styled.div`
  padding: 3.8rem 0;
`;
const WorkerNum = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 65.4rem;
  height: 13.4rem;
  margin: 0 auto;
  padding: 3rem;
  color: #fff;
  li {
    width: 20rem;
    text-align: center;
    span {
      display: inline-block;
      padding-bottom: 1rem;
      font-size: 3.4rem;
      font-weight: 400;
    }
    strong {
      font-size: 3.8rem;
    }
  }
`;