export default function Result({ data }) {
  if (data.reqDriverStatus === "REQUEST" && data.resDriverStatus === "CANCEL") {
    return <div className="status red">거절</div>;
  }
  if (
    data.reqDriverStatus === "REQUEST" &&
    data.resDriverStatus === "REQUEST" &&
    data.managerStatus === "ACCEPT"
  ) {
    return <div className="status">승인</div>;
  }
  if (
    data.reqDriverStatus === "CANCEL" &&
    data.resDriverStatus === "CANCEL" &&
    data.managerStatus === "DENY"
  ) {
    return <div className="status red">반려</div>;
  } else {
    return <div className="status red">취소</div>;
  }
}
