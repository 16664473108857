export default function Result({ data }) {
  if (
    data.reqDriverStatus === "REQUEST" &&
    data.resDriverStatus === "REQUEST" &&
    data.managerStatus === "NONE"
  ) {
    return <div className="status">합의</div>;
  }
  if (data.reqDriverStatus === "REQUEST" && data.resDriverStatus === "CANCEL") {
    return <div className="status red">거절</div>;
  } 
}
