import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { API, routeId, token } from "../../constants";
import RequestAnnual from "./RequestAnnual";

export default function WorkList({
  worker,
  dayOff,
  linkDate,
  annualId,
  openLeaveModal,
  openExchangeModal,
  work,
  linkMonth,
}) {
  const [status, setStatus] = useState("휴무");
  const [showAnnualModal, setShowAnnualModal] = useState(false);
  const [period, setPeriod] = useState("");
  const [runLeave, setRunLeave] = useState(true);
  const [runExchange, setRunExchange] = useState(true);
  const [runAnnual, setRunAnnual] = useState(true);


  const getPeriod = async () => {
    const res = await axios.get(`${API}/work/${routeId}/setting?yearMonth=${linkMonth}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setPeriod(res.data.object.requestTerm);
    console.log(period);
  };
  useEffect(() => {
    getPeriod();
  }, []);

  const startDate = period.slice(0,10).replaceAll('-','');
  console.log(startDate);
  const endDate = period.slice(11,21).replaceAll('-','');
  console.log(endDate);
  const selectDate = linkDate.replaceAll('-','');
  console.log(selectDate);
  const date = new Date();
  const today = format(date, 'yyMMdd');
  console.log(today);
  //날짜별 조건
  useEffect(()=> {
    for(let i = 0; i < work.length; i++) {
        if(work[i].date === linkDate){
          setRunLeave(false);
          setRunExchange(false);
          setRunAnnual(false);
        }
    }
    if(selectDate > today) {
      setRunLeave(false);
      setRunExchange(false);
      setRunAnnual(false);
    }
    if(startDate <= today <= endDate) {
      setRunLeave(false);
      setRunExchange(true);
    } else {
      setRunLeave(true);
      // setRunExchange(true);
    }
  },[]);
  console.log(runLeave);
  console.log(runExchange);
  console.log(runAnnual);
  const handleClick = (e) => {
    setStatus(e.target.name);
  };
  const openAnnualModal = () => {
    setShowAnnualModal(true);
  };
  const closeAnnualModal = () => {
    setShowAnnualModal(false);
  };

  return (
    <WorkerWrap>
      <TabList>
        <BtnTab
          onClick={handleClick}
          name="휴무"
          className={status === "휴무" ? "on" : ""}
        >
          휴무
        </BtnTab>
        <BtnTab
          onClick={handleClick}
          name="근무"
          className={status === "근무" ? "on" : ""}
        >
          근무
        </BtnTab>
      </TabList>
      <CurrentCont>
        {status !== "근무" ? (
          <>
            <Cont className="content">
              <TableBox>
                <TableHead>
                  <span>성명</span>
                  <span>상태</span>
                  <span>현황</span>
                  <span>연락망</span>
                </TableHead>
                <TableBody class="table-body" id="work_table">
                  {dayOff.map((data) => {
                    return (
                      <ListRow class="list-row">
                        <span>{data.driverName}</span>
                        <span>
                          {data.status.includes("ANNUAL") ? "연차" : "휴무"}
                        </span>
                        <span>
                          {data.status.includes("CHECK") ? "확인완료" : "-"}
                        </span>

                        <span class="btn-tel">
                          <a href="tel:전화번호">
                            <img src="/image/ico_tel.png" alt="전화" />
                          </a>
                        </span>
                      </ListRow>
                    );
                  })}
                  ;
                </TableBody>
                <RequestBox>
                  <ul>
                    <li>
                      <button onClick={openLeaveModal} disabled={runLeave}>
                        휴무
                        <br />
                        신청
                      </button>
                    </li>
                    <li>
                      <button onClick={openExchangeModal} disabled={runExchange}>
                        휴무
                        <br />
                        교환
                      </button>
                    </li>
                    <li>
                      <button onClick={openAnnualModal} disabled={runAnnual}>
                        연차
                        <br />
                        신청
                      </button>
                    </li>
                  </ul>
                </RequestBox>
              </TableBox>
              {showAnnualModal ? (
                <RequestAnnual
                  closeModal={closeAnnualModal}
                  linkDate={linkDate}
                  annualId={annualId}
                />
              ) : null}
            </Cont>
          </>
        ) : (
          ""
        )}
        <Cont className="content">
          <TableBox class="table-box">
            <TableHead class="table-head">
              <span>성명</span>
              <span>상태</span>
              <span>현황</span>
              <span>연락망</span>
            </TableHead>
            <TableBody class="table-body" id="rest_table">
              {worker.map((data) => {
                return (
                  <ListRow class="list-row">
                    <span>{data.driverName}</span>
                    <span>
                      {data.status.includes("WORK") ? "근무" : "중도 귀가"}
                    </span>
                    <span>
                      {data.status.includes("CHECK") ? "확인완료" : "-"}
                    </span>

                    <span class="btn-tel">
                      <a href="tel:전화번호">
                        <img src="/image/ico_tel.png" alt="전화" />
                      </a>
                    </span>
                  </ListRow>
                );
              })}
              ;
            </TableBody>
          </TableBox>
        </Cont>
      </CurrentCont>
    </WorkerWrap>
  );
}

const WorkerWrap = styled.div`
  height: calc(100% - 14.5rem);
  border-top-right-radius: 6rem;
  border-top-left-radius: 0.8rem;
  background-color: #f2f3f7;
  overflow: hidden;
`;
const TabList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 4.8rem;
  .on {
    border-color: #f2f3f7;
    background-color: #007473;
    color: #fff;
    box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.16);
  }
`;
const BtnTab = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31.4rem;
  height: 9rem;
  border: 0.3rem solid #767676;
  border-radius: 6.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(1.8rem);
  -webkit-backdrop-filter: blur(1.8rem);
  color: rgba(188, 188, 188, 0.5);
  font-size: 4.2rem;
  font-weight: 700;
  box-shadow: inset 1.4rem 1.4rem 3rem rgba(0, 0, 0, 0.34);
`;
const CurrentCont = styled.div`
  height: calc(100% - 14.5rem);
  overflow: hidden;
`;
const Cont = styled.div`
  height: 100%;
`;
const TableBox = styled.div`
  height: 100%;
  overflow: hidden;
`;
const TableHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 4.8rem 2.2rem;
  border-top: 0.4rem solid #007473;
  border-bottom: 0.4rem solid #007473;
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 8.6rem;
    font-size: 3.8rem;
    font-weight: 700;
    :nth-child(2) {
      width: 15%;
    }
    :nth-child(3) {
      width: 35%;
    }
    :last-child {
      width: 20%;
      font-size: 0;
    }
  }
`;
const TableBody = styled.div`
  height: 100%;
  padding-bottom: 42rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const ListRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 66rem;
  height: 13.4rem;
  margin: 0 auto 2.2rem;
  border: 0.4rem solid #fff;
  border-radius: 2.8rem;
  background-color: #f2f3f7;
  font-size: 3.4rem;
  box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
  span {
    width: 30%;
    text-align: center;
    line-height: 1.2;
    :first-child {
      font-weight: 700;
    }
    :nth-child(2) {
      width: 14%;
    }
    :nth-child(3) {
      width: 35%;
      color: #007473;
      /* font-size: 2.8rem; */
    }
    :last-child {
      width: 10%;
    }
  }
`;

const RequestBox = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 750px;
  padding: 3rem 0 17rem;
  background: #f2f3f7 url("../image/bg_worker_request.png") no-repeat top 3rem
    center;
  background-size: 64rem 18.6rem;
  ul {
    display: flex;
    justify-content: space-between;
    padding: 0 7rem;
    li {
      width: 33.3%;
      text-align: center;
    }
  }
  button {
    display: inline-block;
    width: 100%;
    padding: 4rem 0;
    color: #007473;
    font-size: 4.2rem;
    font-weight: 700;
    :disabled {
      color: #bcbcbc;
    }
  }
`;
