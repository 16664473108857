import TimetableRoundPage from "../components/timetableRound/TimetableRoundPage";



export default function TimetableRound() {
    return (
        <>
            <TimetableRoundPage />
        </>
    )
}