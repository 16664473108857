import { useState } from "react";
import styled from "styled-components";

export default function SelectDate({ myLeaveDate, setSelectDate, setMyLeaveId }) {
  const [showDate, setShowDate] = useState(false);
  const [currentDate, setCurrentDate] = useState("휴무일 선택");

  const handleShowDate = () => {
    setShowDate((prev) => !prev);
  };

  const handleCurrentDate = (e) => {
    const innerText = e.target.getAttribute("value");
    setCurrentDate(innerText);
    setSelectDate(innerText);
    setMyLeaveId(e.target.id);
    console.log(e.target.getAttribute("value"));
  };

  return (
    <>
      <SelectBox onClick={handleShowDate}>
        <SelectBtn show={showDate}>{currentDate}</SelectBtn>
        <ToggleBtn className={showDate ? "turn" : ""}>
          <img src="/image/ico_select.png" alt="" />
        </ToggleBtn>
        <OptionList className={showDate ? "on" : ""}>
          {myLeaveDate?.map((data) => {
            const date =
              data?.date.slice(5, 7) + "월 " + data?.date.slice(8, 10) + "일";
            return (
              <li
                key={data.id}
                onClick={handleCurrentDate}
                value={date}
                id={data.id}
              >
                {date}
              </li>
            );
          })}
        </OptionList>
      </SelectBox>
      {/* <SelectBox onClick={handleShowCompany}>
        <BtnSelect show={showCompany}>{currentCompany}</BtnSelect>
        <OptionListOn show={showCompany}>
          {companyList.map((data) => {
            return (
              <li
                key={data.name}
                onClick={handleCurrentCompany}
                value={data.name}
                name={data.code}
              >
                {data.name}
              </li>
            );
          })}
        </OptionListOn>
      </SelectBox> */}
    </>
  );
}

const SelectBox = styled.div`
  position: relative;
  width: 45.2rem;
  .on {
    display: block;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    padding: 1rem 9.6rem;
    border: 1px solid #707070;
    border-radius: 2rem;
    background-color: #fff;
    font-size: 4.2rem;
  }
  .turn {
    z-index: 3;
    transform: translateY(-50%) rotate(180deg);
  }
  + .title {
    padding-top: 4rem;
  }
`;
const ToggleBtn = styled.span`
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  width: 4.8rem;
  height: 4.8rem;
`;
const SelectBtn = styled.button`
  z-index: 1;
  position: relative;
  display: block;
  width: 100%;
  height: 8.8rem;
  line-height: 8.8rem;
  margin: 0 auto;
  border: 1px solid #707070;
  border-radius: 2rem;
  font-size: 3.8rem;
  box-shadow: inset 0.2rem 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
`;
const OptionList = styled.ul`
  display: none;
  font-size: 3.8rem;
  li {
    display: block;
    padding: 0.8rem 0;
    font-size: 3.8rem;
  }
  li + li {
    border-top: 1px solid #bcbcbc;
  }
`;

// const SelectBox = styled.div`
//   position: relative;
//   width: 45.2rem;
// `;
// const BtnSelect = styled.a`
//   z-index: 3;
//   position: relative;
//   display: flex;
//   width: 29.8rem;
//   height: 7.8rem;
//   line-height: 7.8rem;
//   margin: 0 auto;
//   padding-left: 7rem;
//   border: ${(props) => (!props.show ? "1px solid #fff" : "0")};
//   border-radius: 3.9rem;
//   color: ${(props) => (!props.show ? "#fff" : "#bcbcbc")};
//   font-size: 3.8rem;
//   cursor: pointer;
//   ::after {
//     content: "";
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     right: 5rem;
//     width: 3.8rem;
//     height: 3.8rem;
//     background: ${(props) =>
//       !props.show
//         ? "url('/image/ico_login_arrow_off.png') no-repeat center/100%;"
//         : "url('/image/ico_login_arrow_on.png') no-repeat center/100%;"};
//   }
// `;

// const OptionListOn = styled.ul`
//   display: ${(props) => (!props.show ? "none" : "block")};
//   z-index: 2;
//   position: absolute;
//   top: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 29.8rem;
//   padding: 8rem 3.7rem 2.5rem;
//   border: 1px solid #707070;
//   border-radius: 4rem;
//   background-color: #fff;
//   font-size: 4.2rem;
//   li {
//     display: block;
//     padding: 2rem 0;
//     font-weight: 400;
//     text-align: center;
//     cursor: pointer;
//     + li {
//       border-top: 1px solid #bcbcbc;
//     }
//   }
// `;
