import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import styled from "styled-components";
import { API } from "../../constants";
import LoginModal from "./LoginModal";
import SelectCompany from "./SelectCompany";

export default function LoginPage() {
  const navigate = useNavigate();
  const [companyList, setCompanyList] = useState([]);
  const [companyCode, setCompanyCode] = useState("");
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [showPassword, setShowPassword] = useState({
    type: "password",
    visible: false,
  });

  const handleShowPassword = (e) => {
    setShowPassword(() => {
      if (!showPassword.visible) {
        return { type: "text", visible: true };
      }
      return { type: "password", visible: false };
    });
  };

  const getCompany = async () => {
    const res = await axios.get(`${API}/company`, {
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log(res);
    setCompanyList(res.data.object);
  };

  const handleInputId = (e) => {
    setInputId(e.target.value);
  };
  const handleInputPw = (e) => {
    setInputPw(e.target.value);
  };

  useEffect(() => {
    getCompany();
  }, []);

  const onLogin = async (data) => {
    data.preventDefault();
    const userData = {
      loginId: companyCode + inputId,
      password: inputPw,
    };
    try {
      const res = await axios.post(`${API}/auth/login`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("로그인 성공");
      console.log(res);
      window.sessionStorage.setItem("token", res.data.object.token);
      window.sessionStorage.setItem("companyName", res.data.object.companyName);
      window.sessionStorage.setItem("loginId", res.data.object.loginId);
      window.sessionStorage.setItem("name", res.data.object.name);
      navigate("/home")
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div id="wrap">
        <Container>
          <PageType>운수사업자용</PageType>

          <Logo>
            <div>
              <img src="/image/logo_login.png" alt="kikiB로고" />
            </div>
            <p>스마트 운수 솔루션</p>
          </Logo>

          <form onSubmit={onLogin}>
            <SelectCompany companyList={companyList} setCompanyCode={setCompanyCode}/>
            <IdInput>
              <label>
                <input
                  type="text"
                  name="loginId"
                  required
                  value={inputId}
                  onChange={handleInputId}
                />
                <UnderLine></UnderLine>
                <Number>사원번호</Number>
              </label>
            </IdInput>

            <PasswordInput>
              <label>
                <input
                  type={showPassword.type}
                  name="password"
                  required
                  value={inputPw}
                  onChange={handleInputPw}
                />
                <UnderLine></UnderLine>
                <Number>비밀번호</Number>
                <span onClick={handleShowPassword}>
                  {showPassword.visible ? (
                    <ShowBtn type="button"></ShowBtn>
                  ) : (
                    <HideBtn type="button"></HideBtn>
                  )}
                </span>
              </label>
            </PasswordInput>

            <CheckLabel>
              <CheckBox type="checkbox" />
              <i></i>
              <span>로그인 정보 기억하기</span>
            </CheckLabel>

            <LoginBtn>로그인하기</LoginBtn>
            <PopBtn type="button">로그인 문의</PopBtn>
          </form>
        </Container>
      </div>
      <LoginModal />
    </>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 22rem;
  background: url("/image/bg_login.jpg") no-repeat center/cover;
  font-weight: 700;
`;

const PageType = styled.span`
  position: absolute;
  top: 3rem;
  right: 3.4rem;
  color: #bcbcbc;
  font-size: 3.4rem;
  text-align: right;
`;

const Logo = styled.div`
  margin-bottom: 4.5rem;
  text-align: center;
  p {
    padding-top: 1rem;
    color: #fff;
    font-size: 5rem;
    text-shadow: 0.3rem 0.3rem 0.8rem rgba(0, 0, 0, 0.3);
  }
`;

const IdInput = styled.div`
  position: relative;
  margin-top: 15rem;
  label {
    position: relative;
    display: block;
    width: 40rem;
    height: 6rem;
    margin: 0 auto;
    font-size: 3.8rem;
  }
  input {
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 0.7rem;
    border: none;
    border-radius: 0;
    background: transparent;
    color: #fff;
    font-size: 3.8rem;
    outline: none;
    :focus {
      ~ span {
        top: -0.8rem;
        color: #007473;
        font-size: 2.4rem;
      }
      + i::after {
        background: #007473;
      }
    }
    :valid {
      ~ span {
        top: -0.8rem;
        color: #007473;
        font-size: 2.4rem;
      }
      + i::after {
        background: #007473;
      }
    }
  }
`;
const PasswordInput = styled(IdInput)`
  margin-top: 9rem;
`;
const Number = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.7rem;
  color: #bcbcbc;
  transition: all 0.2s;
`;
const UnderLine = styled.i`
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background: #fff;
    transition: background-color 0.3s;
  }
`;
const ShowBtn = styled.button`
  z-index: 10;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 4.8rem;
  height: 4.8rem;
  background: url("../image/ico_password.png") no-repeat center/100%;
`;
const HideBtn = styled(ShowBtn)`
  background: url("../image/ico_password_on.png") no-repeat center/100%;
`;
const CheckLabel = styled.label`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4rem;
  color: #767676;
  font-size: 3rem;
  text-align: right;
  i {
    display: inline-block;
    width: 3.2rem;
    height: 3.2rem;
    margin-top: 0.2rem;
    margin-right: 1.5rem;
    background: url("../image/ico_check_off.png") no-repeat center/100%;
    vertical-align: text-top;
    box-sizing: border-box;
  }
`;
const CheckBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  :checked + i {
    background-image: url("../image/ico_check_on.png");
  }
  :checked + i + span {
    color: #bcbcbc;
  }
`;
const LoginBtn = styled.button`
  display: block;
  width: 40rem;
  height: 9rem;
  margin: 10rem auto 0;
  border: none;
  border-radius: 4.5rem;
  background: linear-gradient(0.25turn, #2a7da8, #28af86, #007473);
  color: #fff;
  font-size: 3.8rem;
  font-weight: 700;
  cursor: pointer;
`;
const PopBtn = styled.button`
  display: flex;
  margin: 20rem auto 13rem;
  border: none;
  background: none;
  color: #fff;
  font-size: 3.4rem;
  font-weight: 700;
  text-align: center;
`;
