import styled from "styled-components";

export default function WorkDays({workCount, leaveCount, annualCount}) {
  return (
    <>
      <WorkDay class="work-days">
        <DaysList class="days-list">
          <li>
            <span>근무</span>
            <br />
            <strong>{workCount}일</strong>
          </li>
          <li>
            <span>휴무</span>
            <br />
            <strong>{leaveCount}일</strong>
          </li>
          <li>
            <span>연차</span>
            <br />
            <strong>{annualCount}일</strong>
          </li>
        </DaysList>
      </WorkDay>
    </>
  );
}

const WorkDay = styled.div`
  width: 100%;
  max-width: 750px;
  height: 31.6rem;
  margin-top: 2rem;
  padding-top: 2.5rem;
  background: url("/image/bg_calendar_btm_jan.jpg") no-repeat top center/100%;
`;
const DaysList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 66.6rem;
  height: 13.4rem;
  margin: 0 auto;
  padding: 2.5rem 3.3rem;
  color: #fff;
  li {
    position: relative;
    width: 20rem;
    text-align: center;
    span {
      display: inline-block;
      padding-bottom: 1rem;
      font-size: 3.6rem;
      font-weight: 400;
    }
    strong {
      font-size: 3.8rem;
    }
  }
`;
