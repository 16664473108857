import styled from "styled-components";
import ManageHeader from "../manage/ManageHeader";
import ManageNavBar from "../manage/ManageNavigation";
import UserInfo from "../condition/UserInfo";
import { Link } from "react-router-dom";

export default function ConditionPage() {
  return (
    <>
      <div id="wrap">
        <Container class="container management">
          <ManageHeader />
          <Inner class="inner">
            <UserInfo />
            <MenuList class="menu-list">
              <li class="type-row">
                <Link to={"/condition_annual"}>
                  <span class="menu-title">
                    <strong>연차 신청</strong>내역
                  </span>

                  <IconBox class="icon-box">
                    <i className="ico-annual"></i>
                    <i className="ico-alarm on"></i>
                  </IconBox>
                </Link>
              </li>
              <li class="type-row">
                <Link to={"/condition_request"} >
                  <span class="menu-title">
                    휴무 <strong>신청</strong>내역
                  </span>

                  <IconBox class="icon-box">
                    <i className="ico-request"></i>
                    <i className="ico-alarm on"></i>
                  </IconBox>
                </Link>
              </li>
              <li class="type-row">
                <Link to={"/condition_exchange"}>
                  <span class="menu-title">
                    휴무 <strong>교환</strong>내역
                  </span>

                  <IconBox class="icon-box">
                    <i className="ico-exchange"></i>
                    <i className="ico-alarm on"></i>
                  </IconBox>
                </Link>
              </li>
              <li class="type-row">
                <Link to={"/condition_agreement"}>
                  <span class="menu-title">
                    교환 <strong>요청</strong> 목록
                  </span>

                  <IconBox class="icon-box">
                    <i className="ico-agreement"></i>
                    <i className="ico-alarm on"></i>
                  </IconBox>
                </Link>
              </li>
            </MenuList>
          </Inner>
          <ManageNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  position: relative;
  max-width: 750px;
  margin: 0 auto;
`;
const Inner = styled.div`
  background: #f2f3f7 url("/image/bg_daily02.jpg") no-repeat top center/contain;
`;
const MenuList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4.2rem 4.8rem 21rem;
  border-top-right-radius: 6rem;
  border-top-left-radius: 0.8rem;
  background-color: #f2f3f7;
  li {
    position: relative;
    width: 100%;
    height: 15.8rem;
    line-height: 15.8rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2rem;
    background: linear-gradient(-45deg, #f2f3f7, #e8ebf2);
    font-size: 4.8rem;
    box-shadow: 2rem 2rem 3rem rgba(15, 41, 107, 0.12);
    + li {
      margin-top: 4.8rem;
    }
    span {
      line-height: 1.2;
    }
    a {
      display: block;
      height: 100%;
      padding: 0 8.6rem;
    }
  }
`;
const IconBox = styled.div`
  position: absolute;
  top: 0;
  right: 4rem;
  i {
    display: inline-block;
    width: 6.4rem;
    height: 6.4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6.4rem;
    vertical-align: middle;
    + i {
      margin-left: 1rem;
    }
  }
  .ico-alarm {
    background-image: url("/image/ico_alarm_off.png");
  }
  .ico-alarm.on {
    background-image: url("/image/ico_alarm_on.png");
  }
  .ico-annual {
    background-image: url("/image/ico_annual_off.png");
  }
  .ico-request {
    background-image: url("/image/ico_request_off.png");
  }
  .ico-exchange {
    background-image: url("/image/ico_exchange_off.png");
  }
  .ico-agreement {
    background-image: url("/image/ico_agreement_off.png");
  }
`;
