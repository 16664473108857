import styled from "styled-components";
import TimetableHeader from "./TimetableHeader";
import { useEffect, useState } from "react";
import axios from "axios";
import { API, token } from "../../constants";
import TimetableNavBar from "./TimetableNavigation";
import Timetable from "./TableBody";

export default function TimetablePage() {
  const [driverInfo, setDriverInfo] = useState([]);
  const [driverNumber, setDriverNumber] = useState("");
  const getDriver = async () => {
    const res = await axios.get(`${API}/route/driver/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "server-version": "2.0.2",
      },
    });
    setDriverInfo(res.data.object);
    setDriverNumber(res.data.object.busNumber.slice(-4));
  };

  console.log(driverInfo);
  useEffect(() => {
    getDriver();
  }, []);

  return (
    <>
      <div id="wrap">
        <Container class="container timetable">
          <TimetableHeader />

          <UserBox class="user-box">
            <Logo class="logo">
              <img src="/image/logo_bus.png" alt="" />
            </Logo>
            <h1>
              <span id="branchName">{driverInfo.branchName}</span>
              <strong>
                <span id="routeName"> {driverInfo.routeName}번 노선</span>
              </strong>
              <BusNum id="busNumber"> {driverNumber} 차량</BusNum>
            </h1>
          </UserBox>

          <Inner class="inner">
            <TabList class="tab-list">
              <BtnTabOn href="#" class="btn-tab on">
                배차일보
              </BtnTabOn>
              <BtnTab href="#" class="btn-tab btn-route">
                노선도
              </BtnTab>
            </TabList>

            <CurrentContent class="current-content">
              <Content class="content content01 on">
                <TimeTableBox class="timetable-box">
                  <TableHead class="table-head">
                    <span>회차</span>
                    <span>출발</span>
                    {/* <span>도착</span> */}
                    <span>상태</span>
                  </TableHead>
                  <Timetable />
                </TimeTableBox>
                <UpdateBtn class="btn-update">
                  <img src="/image/ico_update.png" alt="새로고침" />
                </UpdateBtn>
              </Content>
            </CurrentContent>
          </Inner>
          <TimetableNavBar />
        </Container>
      </div>
    </>
  );
}

const Container = styled.div`
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  min-height: -webkit-fill-available;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 17.5rem;
  overflow-y: hidden;
`;
const UserBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 13rem;
  padding: 0 0 0 12.6rem;
  h1 {
    font-size: 4.2rem;
    letter-spacing: -0.1px;
    > span {
      font-weight: 400;
    }
  }
`;
const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 4.8rem;
  transform: translateY(-50%);
  width: 7rem;
`;
const BusNum = styled.span`
  font-size: 3.4rem;
`;

const Inner = styled.div`
  height: calc(100% - 10rem);
  background: url("../image/bg_daily.jpg") no-repeat top center/contain;
`;
const TabList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 4.8rem;
`;
const BtnTab = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31.4rem;
  height: 9rem;
  border: 0.3rem solid #767676;
  border-radius: 6.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(1.8rem);
  -webkit-backdrop-filter: blur(1.8rem);
  color: rgba(188, 188, 188, 0.5);
  font-size: 4.2rem;
  font-weight: 700;
  box-shadow: inset 1.4rem 1.4rem 3rem rgba(0, 0, 0, 0.34);
`;
const BtnTabOn = styled(BtnTab)`
  border-color: #f2f3f7;
  background-color: #007473;
  color: #fff;
  box-shadow: none;
`;

const CurrentContent = styled.div`
  height: calc(100% - 14.5rem);
  padding: 2rem 3.6rem 0;
  border-top-right-radius: 6rem;
  border-top-left-radius: 6rem;
  background-color: #f2f3f7;
  overflow: hidden;
`;
const Content = styled.div`
  display: block;
  height: 100%;
`;
const TimeTableBox = styled.div`
  height: 100%;
`;
const TableHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2.2rem;
  padding: 0 1.9rem;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 65.4rem;
    height: 0.4rem;
    background-color: #007473;
  }
  > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 33.3%;
    height: 8.6rem;
    font-size: 3.8rem;
    font-weight: 700;
  }
`;

const UpdateBtn = styled.button`
  position: absolute;
  bottom: 16.2rem;
  right: 3.6rem;
  width: 12rem;
`;
