import styled from "styled-components";
import { API } from "../../constants";
import axios from "axios";

export default function RequestAnnual({ closeModal, linkDate, annualId }) {
    const annualDate = linkDate.slice(5,7) + "월" + linkDate.slice(8,10) + "일";
    console.log(annualDate);
    console.log(annualId);
    const onAnnual = async (data) => {
      data.preventDefault();
      try {
        const res = await axios.post(`${API}/replace/annual?workId=${annualId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("로그인 성공");
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    };
    return (
       <>
            <CommonPopup>
            <p>
              <strong>{annualDate}</strong>에<br />
              <strong className="point">연차</strong>를 <strong>신청</strong>
              하시겠습니까?
            </p>
    
            <BtnBox onClick={e => e.stopPropagation}>
              <BtnCancel onClick={closeModal}>취소</BtnCancel>
              <BtnConfirm onClick={onAnnual}>확인</BtnConfirm>
            </BtnBox>
          </CommonPopup>
       </>
    )
}

const CommonPopup = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 23rem;
  left: 50%;
  transform: translateX(-50%);
  width: 59.4rem;
  padding: 9rem 0 6rem;
  border-radius: 3rem;
  background-color: #fff;
  text-align: center;
  box-shadow: 0.8rem 0.8rem 2.4rem rgba(0, 0, 0, 0.2);
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 17.4rem;
    height: 2.2rem;
    background: url("/image/alert_tag.png") no-repeat center/100%;
  }
  p {
    font-size: 4.2rem;
  line-height: 1.3;
  }
  .point {
    color: #e12c03;
  }
`;
const BtnBox = styled.div`
 display: flex;
  justify-content: center;
  margin-top: 5rem;
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 19.4rem;
    height: 8rem;
    margin: 0 2.5rem;
    border-radius: 9.7rem;
    font-size: 4.2rem;
    font-weight: 700;
  }
`;
const BtnCancel = styled.button`
  border: 0.3rem solid #bcbcbc;
  color: #767676;
  box-shadow: inset 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
`
const BtnConfirm = styled.button`
  background: linear-gradient(0.25turn, #2a7da8, #28af86, #007473);
  color: #fff;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
`
